import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";

var get = "/menu";
var post = "/menu";
export const actGetDataMenu = () => {
  return dispatch => {
    let config = CONFIG_({ url: get, method: "GET" });
    dispatch({ type: "LOADING_MENU_MENU" });
    CONNECTION(config)
      .then(response => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_MENU_MENU",
            message: "Success get data",
            data: data,
            code: status
          });
        }
        return dispatch({ type: "ERROR_MENU_MENU", message: "failed" });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_MENU_MENU",
          message: "Network error.."
        });
      });
  };
};

export const actPostDataMenu = params => {
  return dispatch => {
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_MENU_MENU" });
    CONNECTION(config)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          return dispatch(actGetDataMenu());
        }
        return dispatch({ type: "ERROR_MENU_MENU", message: "failed" });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_MENU_MENU",
          message: "Network error.."
        });
      });
  };
};

export const actUpdateDataMenu = params => {
  return dispatch => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, data: params, method: "PUT" });
    dispatch({ type: "LOADING_MENU_MENU" });
    CONNECTION(config)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_MENU_MENU",
            message: "Success put data"
          });
        }
        return dispatch({ type: "ERROR_MENU_MENU", message: "failed" });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_MENU_MENU",
          message: "Network error.."
        });
      });
  };
};

export const actDetailDataMenu = params => {
  return dispatch => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "GET" });
    dispatch({ type: "LOADING_MENU_MENU" });
    CONNECTION(config)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_MENU_MENU",
            message: "Success get data"
          });
        }
        return dispatch({ type: "ERROR_MENU_MENU", message: "failed" });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_MENU_MENU",
          message: "Network error.."
        });
      });
  };
};

export const actDeleteDataMenu = params => {
  return dispatch => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "DELETE" });
    dispatch({ type: "LOADING_MENU_MENU" });
    CONNECTION(config)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          return dispatch(actGetDataMenu());
        }
        return dispatch({ type: "ERROR_MENU_MENU", message: "failed" });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_MENU_MENU",
          message: "Network error.."
        });
      });
  };
};
