import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

//import Scss
import './vendor/assets/scss/themes.scss';
import './assets/scss/style.scss';
import './App.css';

//imoprt Route
import Route from './app/routes';

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

// Fake Backend
// import fakeBackend from "./vendor/helpers/AuthType/fakeBackend";

// Activating fake backend
// fakeBackend();

function App() {
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
