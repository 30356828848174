const defaultState = {
  status: "default",
  data: [],
  detail: {},
  idSiswa: "",
  code: 500,
  message: "",
};

let response = {};
const SiswaLogin_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_SISWA_ACTIVE":
      response = {
        ...state,
        data: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        status: "success",

        message: action.message ? action.message : "",
      };

      break;
    case "SUCCESS_ACTIVE_DETAIL":
      response = {
        ...state,
        idSiswa: action.id ? action.id : "",
        detail: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        status: "success",

        message: action.message ? action.message : "",
      };

      break;
    case "LOADING_SISWA_ACTIVE":
      response = {
        ...state,
        status: "loading",
      };
      break;
    case "ERROR_SISWA_ACTIVE":
      response = {
        ...state,
        status: "error",
        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
      };
  }

  return response;
};

export default SiswaLogin_lib;
