import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify, warningnotify } from "../../components/ToasNotif/Toast";

var get = "/permission-menu";
var post = "/permission-menu";
export const actGetDataPermissionMenu = () => {
  return dispatch => {
    let config = CONFIG_({ url: get, method: "GET" });
    dispatch({ type: "LOADING_PERMISSIONMENU_PERMISSION" });
    CONNECTION(config)
      .then(response => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_PERMISSIONMENU_PERMISSION",
            message: "Success get data",
            data: data,
            code: status
          });
        }
        return dispatch({
          type: "ERROR_PERMISSIONMENU_PERMISSION",
          message: "failed"
        });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_PERMISSIONMENU_PERMISSION",
          message: "Network error.."
        });
      });
  };
};

export const actPostDataPermissionMenu = params => {
  return dispatch => {
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_PERMISSIONMENU_PERMISSION" });
    CONNECTION(config)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          successnotify("Permission menu has been created");
          return dispatch({
            type: "SUCCESS_PERMISSIONMENU_PERMISSION",
            message: "Success post data"
          });
        }

        warningnotify("Permission menu faild to created");
        return dispatch({
          type: "ERROR_PERMISSIONMENU_PERMISSION",
          message: "failed"
        });
      })
      .catch(response => {
        console.log(response);

        return dispatch({
          type: "ERROR_PERMISSIONMENU_PERMISSION",
          message: "Network error.."
        });
      });
  };
};

export const actUpdateDataPermissionMenu = params => {
  return dispatch => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, data: params, method: "PUT" });
    dispatch({ type: "LOADING_PERMISSIONMENU_PERMISSION" });
    CONNECTION(config)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_PERMISSIONMENU_PERMISSION",
            message: "Success put data"
          });
        }
        return dispatch({
          type: "ERROR_PERMISSIONMENU_PERMISSION",
          message: "failed"
        });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_PERMISSIONMENU_PERMISSION",
          message: "Network error.."
        });
      });
  };
};

export const actDetailDataPermissionMenu = params => {
  return dispatch => {
    const { id } = params;
    let config = CONFIG_({
      url: get + "/" + id + "?filter=typeuser",
      method: "GET"
    });
    dispatch({ type: "LOADING_PERMISSIONMENU_PERMISSION", id: id });
    CONNECTION(config)
      .then(response => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_PERMISSIONMENU_PERMISSION",
            message: "Success get data",
            data: data,
            id: id
          });
        }
        return dispatch({
          type: "ERROR_PERMISSIONMENU_PERMISSION",
          message: "failed",
          id: id
        });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_PERMISSIONMENU_PERMISSION",
          message: "Network error..",
          id: id
        });
      });
  };
};

export const actDeleteDataPermissionMenu = params => {
  return dispatch => {
    const { id } = params;
    let config = CONFIG_({
      url: post + "/" + id + "?filter=typeuser",
      method: "DELETE"
    });
    dispatch({ type: "LOADING_PERMISSIONMENU_PERMISSION", id: id });
    CONNECTION(config)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_PERMISSIONMENU_PERMISSION",
            message: "Success get data",
            id: id
          });
        }
        return dispatch({
          type: "ERROR_PERMISSIONMENU_PERMISSION",
          message: "failed"
        });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_PERMISSIONMENU_PERMISSION",
          message: "Network error.."
        });
      });
  };
};
