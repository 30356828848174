const defaultState = {
  status: 'default',
  data: [],
  code: 500,
  message: '',
  idPayment: '',
  detail: {},
  status: 'default',
};

let response = {};
const Payment_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case 'SUCCESS_PAYMENT_PAYMENT':
      response = {
        ...state,
        data: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        status: 'success',

        message: action.message ? action.message : '',
      };

      break;
    case 'SUCCESS_PAYMENT_SISWA':
      response = {
        ...state,
        data: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        idPayment: action.id ? action.id : '',
        status: 'siswa',

        message: action.message ? action.message : '',
      };

      break;
    case 'SUCCESS_FILTER_PAYMENT':
      response = {
        ...state,
        data: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        status: 'filter',
      };

      break;
    case 'DETAIL_PAYMENT_PAYMENT':
      response = {
        ...state,
        detail: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        status: 'detail',
      };
      break;
    case 'LOADING_PAYMENT_PAYMENT':
      response = {
        ...state,
        status: 'loading',
        idPayment: action.id,
      };
      break;
    case 'ERROR_PAYMENT_PAYMENT':
      response = {
        ...state,
        status: 'error',
        message: action.message ? action.message : '',
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
      };
  }

  return response;
};

export default Payment_lib;
