import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify, warningnotify } from "../../components/ToasNotif/Toast";
import { actGetDataProfile } from "./Profile_act";

// var get = "";
var post = "/auth/login";

export const actPostDataAuth = params => {
  return dispatch => {
    const { email, password } = params;
    const data = {
      username: email,
      password: password
    };
    let config = CONFIG_({ url: post, data: data, method: "POST" });
    dispatch({ type: "LOADING_AUTH_AUTH" });
    CONNECTION(config)
      .then(response => {
        const { status, data } = response;
        if (status === 201) {
          const { access_token } = data;
          localStorage.setItem("access_token", access_token);
          successnotify("Login Success!");
          return dispatch({
            type: "SUCCESS_AUTH_AUTH",
            message: "Success post data",
            code: status
          });

          return dispatch(actGetDataProfile(access_token));
        }
        warningnotify("Login Failed");
        return dispatch({ type: "ERROR_AUTH_AUTH", message: "failed" });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_AUTH_AUTH",
          message: "Network error.."
        });
      });
  };
};
