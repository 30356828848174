import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify } from "../../components/ToasNotif/Toast";
import { __openModal } from "../Modal";

  var get = "/vasetting";
  var post = "/vasetting";
    export const actGetDataVasetting = () => {
      return (dispatch) => {
        let config = CONFIG_({ url: get, method: "GET" });
        dispatch({ type: "LOADING_VASETTING" });
        CONNECTION(config)
          .then((response) => {
            const { status, data} = response;
            if (status === 200) {
              return dispatch({ type: "SUCCESS_VASETTING", message: "Success get data", data:data, code:status});
            }
            return dispatch({ type: "ERROR_VASETTING", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_VASETTING", message: "Network error.." });
          });

      };
    };
    
    export const actPostDataVasetting = (params) => {
      return (dispatch) => {
        let config = CONFIG_({ url: post, data:params, method: "POST" });
        dispatch({ type: "LOADING_VASETTING" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              successnotify('Va Setting has been created');
              dispatch(__openModal({ modal: 'MODAL_ADD_VASETTING', open: false}));
              return dispatch(actGetDataVasetting());
            }
            return dispatch({ type: "ERROR_VASETTING", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_VASETTING", message: "Network error.." });
          });
      };
    };
    
    export const actUpdateDataVasetting = (params) => {
      return (dispatch) => {
        const {id} = params
        let config = CONFIG_({ url: post+'/'+id, data:params, method: "PUT" });
        dispatch({ type: "LOADING_VASETTING" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              successnotify('Va Setting has been updated');
              dispatch(__openModal({ modal: 'MODAL_EDIT_VASETTING', open: false}));
              return dispatch(actGetDataVasetting());
            }
            return dispatch({ type: "ERROR_VASETTING", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_VASETTING", message: "Network error.." });
          });
      };
    };
    
    export const actDetailDataVasetting = (params) => {
      return (dispatch) => {
        const {id} = params
        let config = CONFIG_({ url: post+'/'+id, method: "GET" });
        dispatch({ type: "LOADING_VASETTING" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              return dispatch({ type: "SUCCESS_VASETTING", message: "Success get data" });
            }
            return dispatch({ type: "ERROR_VASETTING", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_VASETTING", message: "Network error.." });
          });
      };
    };

    export const actDeleteDataVasetting = (params) => {
      return (dispatch) => {
        const {id} = params
        let config = CONFIG_({ url: post+'/'+id, method: "DELETE" });
        dispatch({ type: "LOADING_VASETTING" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              successnotify('Data has been deleted');
              return dispatch(actGetDataVasetting());
            }
            return dispatch({ type: "ERROR_VASETTING", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_VASETTING", message: "Network error.." });
          });
      };
    };