import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify, warningnotify } from "../../components/ToasNotif/Toast";
import { __openModal } from "../Modal";

var get = "/siswa-bill";
var post = "/siswa-bill";
export const actGetDataSettingPayment = (id, filter = "student") => {
  return (dispatch) => {
    let config = CONFIG_({
      url: get + "/" + id + "?filter=" + filter,
      method: "GET",
    });
    dispatch({ type: "LOADING_SETTINGPAYMENT_SISWA", id: String(id) });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_SETTINGPAYMENT_SISWA",
            message: "Success get data",
            data: data,
            code: status,
            id: String(id),
          });
        }
        return dispatch({
          type: "ERROR_SETTINGPAYMENT_SISWA",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_SETTINGPAYMENT_SISWA",
          message: "Network error..",
        });
      });
  };
};

export const actPostDataSettingPayment = (params) => {
  return (dispatch) => {
    const { siswa } = params;
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_SETTINGPAYMENT_SISWA", id: siswa });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Setting payment has been created");
          dispatch(
            __openModal({ modal: "MODAL_ADD_SETTINGPAYMENT", open: false })
          );
          return dispatch(actGetDataSettingPayment(siswa));
        }
        warningnotify(response.message);
        return dispatch({
          type: "ERROR_SETTINGPAYMENT_SISWA",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_SETTINGPAYMENT_SISWA",
          message: "Network error..",
        });
      });
  };
};

export const actUpdateDataSettingPayment = (params) => {
  return (dispatch) => {
    const { id, siswa } = params;
    let config = CONFIG_({ url: post + "/" + id, data: params, method: "PUT" });
    dispatch({ type: "LOADING_SETTINGPAYMENT_SISWA", id: String(siswa) });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Setting payment has been update");
          dispatch(
            __openModal({ modal: "MODAL_EDIT_TYPEPAYMENT", open: false })
          );
          return dispatch(actGetDataSettingPayment(String(siswa)));
        }
        warningnotify(response.message);
        return dispatch({
          type: "ERROR_SETTINGPAYMENT_SISWA",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_SETTINGPAYMENT_SISWA",
          message: "Network error..",
        });
      });
  };
};

export const actDetailDataSettingPayment = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "GET" });
    dispatch({ type: "LOADING_SETTINGPAYMENT_SISWA" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_SETTINGPAYMENT_SISWA",
            message: "Success get data",
          });
        }
        return dispatch({
          type: "ERROR_SETTINGPAYMENT_SISWA",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_SETTINGPAYMENT_SISWA",
          message: "Network error..",
        });
      });
  };
};

export const actDeleteDataSettingPayment = (params) => {
  return (dispatch) => {
    const { id, siswa } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "DELETE" });
    dispatch({ type: "LOADING_SETTINGPAYMENT_SISWA" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Setting payment has been deleted");
          return dispatch(actGetDataSettingPayment(atob(siswa)));
        }
        warningnotify(response.message);
        return dispatch({
          type: "ERROR_SETTINGPAYMENT_SISWA",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_SETTINGPAYMENT_SISWA",
          message: "Network error..",
        });
      });
  };
};
