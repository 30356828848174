import React from "react";
// import { Redirect } from "react-router-dom";
import OwnRoutes from "./OwnRoutes";

const BasicAction = React.lazy(() =>
  import("../../vendor/pages/Email/EmailTemplates/BasicAction")
);
const EcommerceAction = React.lazy(() =>
  import("../../vendor/pages/Email/EmailTemplates/EcommerceAction")
);
//CHat
const Chat = React.lazy(() => import("../../vendor/pages/Chat"));
const Calendar = React.lazy(() => import("../../vendor/pages/Calendar"));
// Project
const ProjectList = React.lazy(() =>
  import("../../vendor/pages/Projects/ProjectList")
);
const ProjectOverview = React.lazy(() =>
  import("../../vendor/pages/Projects/ProjectOverview")
);
const CreateProject = React.lazy(() =>
  import("../../vendor/pages/Projects/CreateProject")
);
//Task
const TaskDetails = React.lazy(() =>
  import("../../vendor/pages/Tasks/TaskDetails")
);
const TaskList = React.lazy(() => import("../../vendor/pages/Tasks/TaskList"));
const KanbanBoard = React.lazy(() =>
  import("../../vendor/pages/Tasks/KanbanBoard/Index")
);
//Transactions
const Transactions = React.lazy(() =>
  import("../../vendor/pages/Crypto/Transactions")
);
const BuySell = React.lazy(() => import("../../vendor/pages/Crypto/BuySell"));
const CryproOrder = React.lazy(() =>
  import("../../vendor/pages/Crypto/CryptoOrder")
);
const MyWallet = React.lazy(() => import("../../vendor/pages/Crypto/MyWallet"));
const ICOList = React.lazy(() => import("../../vendor/pages/Crypto/ICOList"));
const KYCVerification = React.lazy(() =>
  import("../../vendor/pages/Crypto/KYCVerification")
);
//Crm Pages
const CrmCompanies = React.lazy(() =>
  import("../../vendor/pages/Crm/CrmCompanies")
);
const CrmContacts = React.lazy(() =>
  import("../../vendor/pages/Crm/CrmContacts")
);
const CrmDeals = React.lazy(() =>
  import("../../vendor/pages/Crm/CrmDeals/index")
);
const CrmLeads = React.lazy(() =>
  import("../../vendor/pages/Crm/CrmLeads/index")
);
//Invoices
const InvoiceList = React.lazy(() =>
  import("../../vendor/pages/Invoices/InvoiceList")
);
const InvoiceCreate = React.lazy(() =>
  import("../../vendor/pages/Invoices/InvoiceCreate")
);
const InvoiceDetails = React.lazy(() =>
  import("../../vendor/pages/Invoices/InvoiceDetails")
);
// Support Tickets
const ListView = React.lazy(() =>
  import("../../vendor/pages/SupportTickets/ListView")
);
const TicketsDetails = React.lazy(() =>
  import("../../vendor/pages/SupportTickets/TicketsDetails")
);
// //Ecommerce Pages
const EcommerceProducts = React.lazy(() =>
  import("../../vendor/pages/Ecommerce/EcommerceProducts/index")
);
const EcommerceProductDetail = React.lazy(() =>
  import(
    "../../vendor/pages/Ecommerce/EcommerceProducts/EcommerceProductDetail"
  )
);
const EcommerceAddProduct = React.lazy(() =>
  import("../../vendor/pages/Ecommerce/EcommerceProducts/EcommerceAddProduct")
);
const EcommerceOrders = React.lazy(() =>
  import("../../vendor/pages/Ecommerce/EcommerceOrders/index")
);
const EcommerceOrderDetail = React.lazy(() =>
  import("../../vendor/pages/Ecommerce/EcommerceOrders/EcommerceOrderDetail")
);
const EcommerceCustomers = React.lazy(() =>
  import("../../vendor/pages/Ecommerce/EcommerceCustomers/index")
);
const EcommerceCart = React.lazy(() =>
  import("../../vendor/pages/Ecommerce/EcommerceCart")
);
const EcommerceCheckout = React.lazy(() =>
  import("../../vendor/pages/Ecommerce/EcommerceCheckout")
);
const EcommerceSellers = React.lazy(() =>
  import("../../vendor/pages/Ecommerce/EcommerceSellers/index")
);
const EcommerceSellerDetail = React.lazy(() =>
  import("../../vendor/pages/Ecommerce/EcommerceSellers/EcommerceSellerDetail")
);

// NFT Marketplace Pages
const Marketplace = React.lazy(() =>
  import("../../vendor/pages/NFTMarketplace/Marketplace")
);
const Collections = React.lazy(() =>
  import("../../vendor/pages/NFTMarketplace/Collections")
);
const CreateNFT = React.lazy(() =>
  import("../../vendor/pages/NFTMarketplace/CreateNFT")
);
const Creators = React.lazy(() =>
  import("../../vendor/pages/NFTMarketplace/Creators")
);
const ExploreNow = React.lazy(() =>
  import("../../vendor/pages/NFTMarketplace/ExploreNow")
);
const ItemDetails = React.lazy(() =>
  import("../../vendor/pages/NFTMarketplace/Itemdetails")
);
const LiveAuction = React.lazy(() =>
  import("../../vendor/pages/NFTMarketplace/LiveAuction")
);
const Ranking = React.lazy(() =>
  import("../../vendor/pages/NFTMarketplace/Ranking")
);
const WalletConnect = React.lazy(() =>
  import("../../vendor/pages/NFTMarketplace/WalletConnect")
);
// Base Ui
const UiAlerts = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiAlerts/UiAlerts")
);
const UiBadges = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiBadges/UiBadges")
);
const UiButtons = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiButtons/UiButtons")
);
const UiColors = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiColors/UiColors")
);
const UiCards = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiCards/UiCards")
);
const UiCarousel = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiCarousel/UiCarousel")
);
const UiDropdowns = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiDropdowns/UiDropdowns")
);
const UiGrid = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiGrid/UiGrid")
);
const UiImages = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiImages/UiImages")
);
const UiTabs = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiTabs/UiTabs")
);
const UiAccordions = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiAccordion&Collapse/UiAccordion&Collapse")
);
const UiModals = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiModals/UiModals")
);
const UiOffcanvas = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiOffcanvas/UiOffcanvas")
);
const UiPlaceholders = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiPlaceholders/UiPlaceholders")
);

const UiProgress = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiProgress/UiProgress")
);
const UiNotifications = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiNotifications/UiNotifications")
);
const UiMediaobject = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiMediaobject/UiMediaobject")
);
const UiEmbedVideo = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiEmbedVideo/UiEmbedVideo")
);
const UiTypography = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiTypography/UiTypography")
);
const UiList = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiLists/UiLists")
);
const UiGeneral = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiGeneral/UiGeneral")
);
const UiRibbons = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiRibbons/UiRibbons")
);
const UiUtilities = React.lazy(() =>
  import("../../vendor/pages/BaseUi/UiUtilities/UiUtilities")
);
// Advance Ui
const UiNestableList = React.lazy(() =>
  import("../../vendor/pages/AdvanceUi/UiNestableList/UiNestableList")
);
const UiScrollbar = React.lazy(() =>
  import("../../vendor/pages/AdvanceUi/UiScrollbar/UiScrollbar")
);
const UiAnimation = React.lazy(() =>
  import("../../vendor/pages/AdvanceUi/UiAnimation/UiAnimation")
);
const UiTour = React.lazy(() =>
  import("../../vendor/pages/AdvanceUi/UiTour/UiTour")
);
const UiSwiperSlider = React.lazy(() =>
  import("../../vendor/pages/AdvanceUi/UiSwiperSlider/UiSwiperSlider")
);
const UiRatings = React.lazy(() =>
  import("../../vendor/pages/AdvanceUi/UiRatings/UiRatings")
);
const UiHighlight = React.lazy(() =>
  import("../../vendor/pages/AdvanceUi/UiHighlight/UiHighlight")
);
// Widgets
const Widgets = React.lazy(() => import("../../vendor/pages/Widgets/Index"));
//Forms
const BasicElements = React.lazy(() =>
  import("../../vendor/pages/Forms/BasicElements/BasicElements")
);
const FormSelect = React.lazy(() =>
  import("../../vendor/pages/Forms/FormSelect/FormSelect")
);
const FormEditor = React.lazy(() =>
  import("../../vendor/pages/Forms/FormEditor/FormEditor")
);
const CheckBoxAndRadio = React.lazy(() =>
  import("../../vendor/pages/Forms/CheckboxAndRadio/CheckBoxAndRadio")
);
const Masks = React.lazy(() => import("../../vendor/pages/Forms/Masks/Masks"));
const FileUpload = React.lazy(() =>
  import("../../vendor/pages/Forms/FileUpload/FileUpload")
);
const FormPickers = React.lazy(() =>
  import("../../vendor/pages/Forms/FormPickers/FormPickers")
);
const FormRangeSlider = React.lazy(() =>
  import("../../vendor/pages/Forms/FormRangeSlider/FormRangeSlider")
);
const Formlayouts = React.lazy(() =>
  import("../../vendor/pages/Forms/FormLayouts/Formlayouts")
);
const FormValidation = React.lazy(() =>
  import("../../vendor/pages/Forms/FormValidation/FormValidation")
);
const FormWizard = React.lazy(() =>
  import("../../vendor/pages/Forms/FormWizard/FormWizard")
);
const FormAdvanced = React.lazy(() =>
  import("../../vendor/pages/Forms/FormAdvanced/FormAdvanced")
);
const Select2 = React.lazy(() =>
  import("../../vendor/pages/Forms/Select2/Select2")
);
//Tables
const BasicTables = React.lazy(() =>
  import("../../vendor/pages/Tables/BasicTables/BasicTables")
);
const GridTables = React.lazy(() =>
  import("../../vendor/pages/Tables/GridTables/GridTables")
);
const ListTables = React.lazy(() =>
  import("../../vendor/pages/Tables/ListTables/ListTables")
);
const DataTables = React.lazy(() =>
  import("../../vendor/pages/Tables/DataTables/DataTables")
);
//Icon pages
const RemixIcons = React.lazy(() =>
  import("../../vendor/pages/Icons/RemixIcons/RemixIcons")
);
const BoxIcons = React.lazy(() =>
  import("../../vendor/pages/Icons/BoxIcons/BoxIcons")
);
const MaterialDesign = React.lazy(() =>
  import("../../vendor/pages/Icons/MaterialDesign/MaterialDesign")
);
const FeatherIcons = React.lazy(() =>
  import("../../vendor/pages/Icons/FeatherIcons/FeatherIcons")
);
const LineAwesomeIcons = React.lazy(() =>
  import("../../vendor/pages/Icons/LineAwesomeIcons/LineAwesomeIcons")
);
//Maps
const GoogleMaps = React.lazy(() =>
  import("../../vendor/pages/Maps/GoogleMaps/GoogleMaps")
);
const VectorMaps = React.lazy(() =>
  import("../../vendor/pages/Maps/VectorMaps/VectorMaps")
);
const LeafletMaps = React.lazy(() =>
  import("../../vendor/pages/Maps/LeafletMaps/LeafletMaps")
);
//AuthenticationInner pages
const BasicSignIn = React.lazy(() =>
  import("../../vendor/pages/AuthenticationInner/Login/BasicSignIn")
);
const CoverSignIn = React.lazy(() =>
  import("../../vendor/pages/AuthenticationInner/Login/CoverSignIn")
);
const BasicSignUp = React.lazy(() =>
  import("../../vendor/pages/AuthenticationInner/Register/BasicSignUp")
);
const CoverSignUp = React.lazy(() =>
  import("../../vendor/pages/AuthenticationInner/Register/CoverSignUp")
); //pages
const BasicPasswReset = React.lazy(() =>
  import("../../vendor/pages/AuthenticationInner/PasswordReset/BasicPasswReset")
);
const Starter = React.lazy(() =>
  import("../../vendor/pages/Pages/Starter/Starter")
);
const SimplePage = React.lazy(() =>
  import("../../vendor/pages/Pages/Profile/SimplePage/SimplePage")
);
const Settings = React.lazy(() =>
  import("../../vendor/pages/Pages/Profile/Settings/Settings")
);
const Team = React.lazy(() => import("../../vendor/pages/Pages/Team/Team"));
const Timeline = React.lazy(() =>
  import("../../vendor/pages/Pages/Timeline/Timeline")
);
const Faqs = React.lazy(() => import("../../vendor/pages/Pages/Faqs/Faqs"));
const Pricing = React.lazy(() =>
  import("../../vendor/pages/Pages/Pricing/Pricing")
);
const Gallery = React.lazy(() =>
  import("../../vendor/pages/Pages/Gallery/Gallery")
);
const Maintenance = React.lazy(() =>
  import("../../vendor/pages/Pages/Maintenance/Maintenance")
);
const ComingSoon = React.lazy(() =>
  import("../../vendor/pages/Pages/ComingSoon/ComingSoon")
);
const SiteMap = React.lazy(() =>
  import("../../vendor/pages/Pages/SiteMap/SiteMap")
);
const SearchResults = React.lazy(() =>
  import("../../vendor/pages/Pages/SearchResults/SearchResults")
);
const CoverPasswReset = React.lazy(() =>
  import("../../vendor/pages/AuthenticationInner/PasswordReset/CoverPasswReset")
);
const BasicLockScreen = React.lazy(() =>
  import("../../vendor/pages/AuthenticationInner/LockScreen/BasicLockScr")
);
const CoverLockScreen = React.lazy(() =>
  import("../../vendor/pages/AuthenticationInner/LockScreen/CoverLockScr")
);

const BasicLogout = React.lazy(() =>
  import("../../vendor/pages/AuthenticationInner/Logout/BasicLogout")
);
const CoverLogout = React.lazy(() =>
  import("../../vendor/pages/AuthenticationInner/Logout/CoverLogout")
);
const BasicSuccessMsg = React.lazy(() =>
  import(
    "../../vendor/pages/AuthenticationInner/SuccessMessage/BasicSuccessMsg"
  )
);

const CoverSuccessMsg = React.lazy(() =>
  import(
    "../../vendor/pages/AuthenticationInner/SuccessMessage/CoverSuccessMsg"
  )
);
const BasicTwosVerify = React.lazy(() =>
  import(
    "../../vendor/pages/AuthenticationInner/TwoStepVerification/BasicTwosVerify"
  )
);
const CoverTwosVerify = React.lazy(() =>
  import(
    "../../vendor/pages/AuthenticationInner/TwoStepVerification/CoverTwosVerify"
  )
);
const Basic404 = React.lazy(() =>
  import("../../vendor/pages/AuthenticationInner/Errors/Basic404")
);

const Cover404 = React.lazy(() =>
  import("../../vendor/pages/AuthenticationInner/Errors/Cover404")
);

const Alt404 = React.lazy(() =>
  import("../../vendor/pages/AuthenticationInner/Errors/Alt404")
);

const Error500 = React.lazy(() =>
  import("../../vendor/pages/AuthenticationInner/Errors/Error500")
);

const BasicPasswCreate = React.lazy(() =>
  import(
    "../../vendor/pages/AuthenticationInner/PasswordCreate/BasicPasswCreate"
  )
);
const CoverPasswCreate = React.lazy(() =>
  import(
    "../../vendor/pages/AuthenticationInner/PasswordCreate/CoverPasswCreate"
  )
);
const Offlinepage = React.lazy(() =>
  import("../../vendor/pages/AuthenticationInner/Errors/Offlinepage")
);

//login
const Login = React.lazy(() => import("../../app/pages/Authentication/Login"));
const ForgetPasswordPage = React.lazy(() =>
  import("../../vendor/pages/Authentication/ForgetPassword")
);
const Logout = React.lazy(() =>
  import("../../vendor/pages/Authentication/Logout")
);
const Register = React.lazy(() =>
  import("../../vendor/pages/Authentication/Register")
);
//Charts
const LineCharts = React.lazy(() =>
  import("../../vendor/pages/Charts/ApexCharts/LineCharts")
);
const AreaCharts = React.lazy(() =>
  import("../../vendor/pages/Charts/ApexCharts/AreaCharts")
);
const ColumnCharts = React.lazy(() =>
  import("../../vendor/pages/Charts/ApexCharts/ColumnCharts")
);
const BarCharts = React.lazy(() =>
  import("../../vendor/pages/Charts/ApexCharts/BarCharts")
);

const MixedCharts = React.lazy(() =>
  import("../../vendor/pages/Charts/ApexCharts/MixedCharts")
);

const TimelineCharts = React.lazy(() =>
  import("../../vendor/pages/Charts/ApexCharts/TimelineCharts")
);
const CandlestickChart = React.lazy(() =>
  import("../../vendor/pages/Charts/ApexCharts/CandlestickChart")
);

const BoxplotCharts = React.lazy(() =>
  import("../../vendor/pages/Charts/ApexCharts/BoxplotCharts")
);

const BubbleChart = React.lazy(() =>
  import("../../vendor/pages/Charts/ApexCharts/BubbleChart")
);
const ScatterCharts = React.lazy(() =>
  import("../../vendor/pages/Charts/ApexCharts/ScatterCharts")
);

const HeatmapCharts = React.lazy(() =>
  import("../../vendor/pages/Charts/ApexCharts/HeatmapCharts")
);
const TreemapCharts = React.lazy(() =>
  import("../../vendor/pages/Charts/ApexCharts/TreemapCharts")
);
const PieCharts = React.lazy(() =>
  import("../../vendor/pages/Charts/ApexCharts/PieCharts")
);
const RadialbarCharts = React.lazy(() =>
  import("../../vendor/pages/Charts/ApexCharts/RadialbarCharts")
);
const RadarCharts = React.lazy(() =>
  import("../../vendor/pages/Charts/ApexCharts/RadarCharts")
);
const PolarCharts = React.lazy(() =>
  import("../../vendor/pages/Charts/ApexCharts/PolarCharts")
);

const ChartsJs = React.lazy(() =>
  import("../../vendor/pages/Charts/ChartsJs/index")
);
const Echarts = React.lazy(() =>
  import("../../vendor/pages/Charts/ECharts/index")
);

// Landing Index
const OnePage = React.lazy(() => import("../../vendor/pages/Landing/OnePage"));
const NFTLanding = React.lazy(() =>
  import("../../vendor/pages/Landing/NFTLanding")
);
// User Profile
const UserProfile = React.lazy(() =>
  import("../../vendor/pages/Authentication/user-profile")
);

// ownroute
//Dashboard
const DashboardAnalytics = React.lazy(() =>
  import("../../vendor/pages/DashboardAnalytics")
);
const DashboardCrm = React.lazy(() =>
  import("../../vendor/pages/DashboardCrm")
);
const DashboardEcommerce = React.lazy(() =>
  import("../../vendor/pages/DashboardEcommerce")
);

const DashboardCrypto = React.lazy(() =>
  import("../../vendor/pages/DashboardCrypto")
);

const DashboardNFT = React.lazy(() =>
  import("../../vendor/pages/DashboardNFT")
);

//Calendar
// Email box
const MailInbox = React.lazy(() => import("../../vendor/pages/EmailInbox"));

const basicRoute = [
  { path: "/dashboard-analytics", component: DashboardAnalytics },
  { path: "/dashboard-crm", component: DashboardCrm },
  { path: "/dashboard-project", component: DashboardEcommerce },
  { path: "/index", component: DashboardEcommerce },
  { path: "/dashboard-crypto", component: DashboardCrypto },
  { path: "/dashboard-nft", component: DashboardNFT },
  { path: "/apps-calendar", component: Calendar },
  { path: "/apps-ecommerce-products", component: EcommerceProducts },
  {
    path: "/apps-ecommerce-product-details",
    component: EcommerceProductDetail,
  },
  { path: "/apps-ecommerce-add-product", component: EcommerceAddProduct },
  { path: "/apps-ecommerce-orders", component: EcommerceOrders },
  { path: "/apps-ecommerce-order-details", component: EcommerceOrderDetail },
  { path: "/apps-ecommerce-customers", component: EcommerceCustomers },
  { path: "/apps-ecommerce-cart", component: EcommerceCart },
  { path: "/apps-ecommerce-checkout", component: EcommerceCheckout },
  { path: "/apps-ecommerce-sellers", component: EcommerceSellers },
  { path: "/apps-ecommerce-seller-details", component: EcommerceSellerDetail },

  //Chat
  { path: "/apps-chat", component: Chat },

  //EMail
  { path: "/apps-mailbox", component: MailInbox },
  { path: "/apps-email-basic", component: BasicAction },
  { path: "/apps-email-ecommerce", component: EcommerceAction },

  //Projects
  { path: "/apps-projects-list", component: ProjectList },
  { path: "/apps-projects-overview", component: ProjectOverview },
  { path: "/apps-projects-create", component: CreateProject },

  //Task
  { path: "/apps-tasks-list-view", component: TaskList },
  { path: "/apps-tasks-details", component: TaskDetails },
  { path: "/apps-tasks-kanban", component: KanbanBoard },
  //Crm
  { path: "/apps-crm-contacts", component: CrmContacts },
  { path: "/apps-crm-companies", component: CrmCompanies },
  { path: "/apps-crm-deals", component: CrmDeals },
  { path: "/apps-crm-leads", component: CrmLeads },

  //Invoices
  { path: "/apps-invoices-list", component: InvoiceList },
  { path: "/apps-invoices-details", component: InvoiceDetails },
  { path: "/apps-invoices-create", component: InvoiceCreate },

  //Supports Tickets
  { path: "/apps-tickets-list", component: ListView },
  { path: "/apps-tickets-details", component: TicketsDetails },

  //transactions
  { path: "/apps-crypto-transactions", component: Transactions },
  { path: "/apps-crypto-buy-sell", component: BuySell },
  { path: "/apps-crypto-orders", component: CryproOrder },
  { path: "/apps-crypto-wallet", component: MyWallet },
  { path: "/apps-crypto-ico", component: ICOList },
  { path: "/apps-crypto-kyc", component: KYCVerification },

  // NFT Marketplace
  { path: "/apps-nft-marketplace", component: Marketplace },
  { path: "/apps-nft-collections", component: Collections },
  { path: "/apps-nft-create", component: CreateNFT },
  { path: "/apps-nft-creators", component: Creators },
  { path: "/apps-nft-explore", component: ExploreNow },
  { path: "/apps-nft-item-details", component: ItemDetails },
  { path: "/apps-nft-auction", component: LiveAuction },
  { path: "/apps-nft-ranking", component: Ranking },
  { path: "/apps-nft-wallet", component: WalletConnect },

  //charts
  { path: "/charts-apex-line", component: LineCharts },
  { path: "/charts-apex-area", component: AreaCharts },
  { path: "/charts-apex-column", component: ColumnCharts },
  { path: "/charts-apex-bar", component: BarCharts },
  { path: "/charts-apex-mixed", component: MixedCharts },
  { path: "/charts-apex-timeline", component: TimelineCharts },
  { path: "/charts-apex-candlestick", component: CandlestickChart },
  { path: "/charts-apex-boxplot", component: BoxplotCharts },
  { path: "/charts-apex-bubble", component: BubbleChart },
  { path: "/charts-apex-scatter", component: ScatterCharts },
  { path: "/charts-apex-heatmap", component: HeatmapCharts },
  { path: "/charts-apex-treemap", component: TreemapCharts },
  { path: "/charts-apex-pie", component: PieCharts },
  { path: "/charts-apex-radialbar", component: RadialbarCharts },
  { path: "/charts-apex-radar", component: RadarCharts },
  { path: "/charts-apex-polar", component: PolarCharts },

  { path: "/charts-chartjs", component: ChartsJs },
  { path: "/charts-echarts", component: Echarts },

  // Base Ui
  { path: "/ui-alerts", component: UiAlerts },
  { path: "/ui-badges", component: UiBadges },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-dropdowns", component: UiDropdowns },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-tabs", component: UiTabs },
  { path: "/ui-accordions", component: UiAccordions },
  { path: "/ui-modals", component: UiModals },
  { path: "/ui-offcanvas", component: UiOffcanvas },
  { path: "/ui-placeholders", component: UiPlaceholders },
  { path: "/ui-progress", component: UiProgress },
  { path: "/ui-notifications", component: UiNotifications },
  { path: "/ui-media", component: UiMediaobject },
  { path: "/ui-embed-video", component: UiEmbedVideo },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-lists", component: UiList },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-ribbons", component: UiRibbons },
  { path: "/ui-utilities", component: UiUtilities },

  // Advance Ui
  { path: "/advance-ui-nestable", component: UiNestableList },
  { path: "/advance-ui-scrollbar", component: UiScrollbar },
  { path: "/advance-ui-animation", component: UiAnimation },
  { path: "/advance-ui-tour", component: UiTour },
  { path: "/advance-ui-swiper", component: UiSwiperSlider },
  { path: "/advance-ui-ratings", component: UiRatings },
  { path: "/advance-ui-highlight", component: UiHighlight },

  // Widgets
  { path: "/widgets", component: Widgets },

  // Forms
  { path: "/forms-elements", component: BasicElements },
  { path: "/forms-select", component: FormSelect },
  { path: "/forms-editors", component: FormEditor },
  { path: "/forms-checkboxes-radios", component: CheckBoxAndRadio },
  { path: "/forms-masks", component: Masks },
  { path: "/forms-file-uploads", component: FileUpload },
  { path: "/forms-pickers", component: FormPickers },
  { path: "/forms-range-sliders", component: FormRangeSlider },
  { path: "/forms-layouts", component: Formlayouts },
  { path: "/forms-validation", component: FormValidation },
  { path: "/forms-wizard", component: FormWizard },
  { path: "/forms-advanced", component: FormAdvanced },
  { path: "/forms-select2", component: Select2 },

  //Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-gridjs", component: GridTables },
  { path: "/tables-listjs", component: ListTables },
  { path: "/tables-datatables", component: DataTables },

  //Icons
  { path: "/icons-remix", component: RemixIcons },
  { path: "/icons-boxicons", component: BoxIcons },
  { path: "/icons-materialdesign", component: MaterialDesign },
  { path: "/icons-feather", component: FeatherIcons },
  { path: "/icons-lineawesome", component: LineAwesomeIcons },

  //Maps
  { path: "/maps-google", component: GoogleMaps },
  { path: "/maps-vector", component: VectorMaps },
  { path: "/maps-leaflet", component: LeafletMaps },

  //Pages
  { path: "/pages-starter", component: Starter },
  { path: "/pages-profile", component: SimplePage },
  { path: "/pages-profile-settings", component: Settings },
  { path: "/pages-team", component: Team },
  { path: "/pages-timeline", component: Timeline },
  { path: "/pages-faqs", component: Faqs },
  { path: "/pages-gallery", component: Gallery },
  { path: "/pages-pricing", component: Pricing },
  { path: "/pages-sitemap", component: SiteMap },
  { path: "/pages-search-results", component: SearchResults },

  //User Profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
];

const publicRoutes = [
  // Authentication Page
  { path: "/", component: Login },
  { path: "/logout", component: Logout },
  { path: "/forgot-password", component: ForgetPasswordPage },
  { path: "/register", component: Register },
  //AuthenticationInner pages
  { path: "/auth-signin-basic", component: BasicSignIn },
  { path: "/auth-signin-cover", component: CoverSignIn },
  { path: "/auth-signup-basic", component: BasicSignUp },
  { path: "/auth-signup-cover", component: CoverSignUp },
  { path: "/auth-pass-reset-basic", component: BasicPasswReset },
  { path: "/auth-pass-reset-cover", component: CoverPasswReset },
  { path: "/auth-lockscreen-basic", component: BasicLockScreen },
  { path: "/auth-lockscreen-cover", component: CoverLockScreen },
  { path: "/auth-logout-basic", component: BasicLogout },
  { path: "/auth-logout-cover", component: CoverLogout },
  { path: "/auth-success-msg-basic", component: BasicSuccessMsg },
  { path: "/auth-success-msg-cover", component: CoverSuccessMsg },
  { path: "/auth-twostep-basic", component: BasicTwosVerify },
  { path: "/auth-twostep-cover", component: CoverTwosVerify },
  { path: "/auth-404-basic", component: Basic404 },
  { path: "/auth-404-cover", component: Cover404 },
  { path: "/auth-404-alt", component: Alt404 },
  { path: "/auth-500", component: Error500 },
  { path: "/pages-maintenance", component: Maintenance },
  { path: "/pages-coming-soon", component: ComingSoon },

  { path: "/landing", component: OnePage },
  { path: "/nft-landing", component: NFTLanding },

  { path: "/auth-pass-change-basic", component: BasicPasswCreate },
  { path: "/auth-pass-change-cover", component: CoverPasswCreate },
  { path: "/auth-offline", component: Offlinepage },
];

const authProtectedRoutes = OwnRoutes.concat(basicRoute);

export { publicRoutes, authProtectedRoutes };
