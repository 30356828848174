import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

//import images
import { Link } from "react-router-dom";
import { actPostDataLogout } from "../../../app/modules/Logout";

const ProfileDropdown = () => {
  const { user } = useSelector(state => ({
    user: state.Profile.user
  }));

  const dispatch = useDispatch();

  const [profile, setProfile] = useState({
    fullname: "No Name",
    symbol: "NA",
    type: "Admin"
  });

  const modulProfile = useSelector(state => state.modulProfile);
  const { data } = modulProfile;
  const [userName, setUserName] = useState("Admin");

  useEffect(
    () => {
      if (sessionStorage.getItem("authUser")) {
        const obj = JSON.parse(sessionStorage.getItem("authUser"));
        setUserName(user.first_name || obj.data.first_name || "Admin");
      }
    },
    [userName, user]
  );

  useEffect(
    () => {
      if (Object.keys(data).length > 0) {
        const { fullname } = data;
        setProfile({
          ...data,
          type: "admin",
          symbol: fullname.slice(0, 2)
        });
      }
    },
    [data]
  );

  //Dropdown Toggle
  const [isProfileDropdown, setIsProfileDropdown] = useState(false);
  const toggleProfileDropdown = () => {
    setIsProfileDropdown(!isProfileDropdown);
  };

  const handleLogout = () => {
    console.log("logout");
    dispatch(actPostDataLogout());
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={isProfileDropdown}
        toggle={toggleProfileDropdown}
        className="ms-sm-3 header-item topbar-user"
      >
        <DropdownToggle tag="button" type="button" className="btn">
          <span className="d-flex align-items-center">
            <div className="rounded-circle header-profile-user header-profile-text ">
              {profile.symbol}
            </div>

            <span className="text-start ms-xl-2">
              <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text text-capitalize">
                {profile.fullname}
              </span>
              <span className="d-none d-xl-block ms-1 fs-13 text-muted user-name-sub-text text-capitalize">
                {profile.type}
              </span>
            </span>
          </span>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <h6 className="dropdown-header text-capitalize">
            Welcome {profile.fullname}!
          </h6>
          {/* <Link to={"/user-profile"} className="dropdown-item">
            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1" />
            <span className="align-middle">Profile</span>
          </Link> */}

          <div className="dropdown-divider" />

          {/* <DropdownItem href="/pages-profile-settings">
            <span className="badge bg-soft-success text-success mt-1 float-end">
              New
            </span>
            <i className="mdi mdi-cog-outline text-muted fs-16 align-middle me-1" />{" "}
            <span className="align-middle">Settings</span>
          </DropdownItem> */}
          <DropdownItem href="#" onClick={() => handleLogout()}>
            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1" />{" "}
            <span className="align-middle" data-key="t-logout">
              Logout
            </span>
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default ProfileDropdown;
