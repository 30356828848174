import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { data } from "autoprefixer";
import { successnotify } from "../../components/ToasNotif/Toast";
import { actGetDataInvoiceBySiswa } from "./Invoice_act";

var get = "/item-invoice";
var post = "/item-invoice";
export const actGetDataInvoiceRequestPayment = () => {
  return (dispatch) => {
    let config = CONFIG_({ url: get, method: "GET" });
    dispatch({ type: "LOADING_INVOICEREQUESTPAYMENT_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_INVOICEREQUESTPAYMENT_INVOICE",
            message: "Success get data",
            data: data,
            code: status,
          });
        }
        return dispatch({
          type: "ERROR_INVOICEREQUESTPAYMENT_INVOICE",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICEREQUESTPAYMENT_INVOICE",
          message: "Network error..",
        });
      });
  };
};

export const actPostDataInvoiceRequestPayment = (params) => {
  return (dispatch) => {
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_INVOICEREQUESTPAYMENT_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 201) {
          return dispatch({
            type: "SUCCESS_INVOICEREQUESTPAYMENT_INVOICE",
            message: "Success post data",
          });
        }
        return dispatch({
          type: "ERROR_INVOICEREQUESTPAYMENT_INVOICE",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICEREQUESTPAYMENT_INVOICE",
          message: "Network error..",
        });
      });
  };
};

export const actUpdateDataInvoiceRequestPayment = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, data: params, method: "PUT" });
    dispatch({ type: "LOADING_INVOICEREQUESTPAYMENT_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_INVOICEREQUESTPAYMENT_INVOICE",
            message: "Success put data",
          });
        }
        return dispatch({
          type: "ERROR_INVOICEREQUESTPAYMENT_INVOICE",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICEREQUESTPAYMENT_INVOICE",
          message: "Network error..",
        });
      });
  };
};
export const actUpdateAllocationInvoiceRequestPayment = (params) => {
  return (dispatch) => {
    const { id, refrence_id } = params;
    let config = CONFIG_({
      url: post + "/allocation/" + id,
      data: params,
      method: "PUT",
    });
    dispatch({ type: "LOADING_INVOICEREQUESTPAYMENT_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status, message } = response;
        if (status === 200) {
          successnotify("Allocation has been update");
          dispatch(actGetDataInvoiceBySiswa(refrence_id));
          return dispatch({
            type: "SUCCESS_INVOICEREQUESTPAYMENT_ALLOCATION",
            message: "Success put allocation",
          });
        }
        successnotify(message);

        return dispatch({
          type: "ERROR_INVOICEREQUESTPAYMENT_INVOICE",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICEREQUESTPAYMENT_INVOICE",
          message: "Network error..",
        });
      });
  };
};

export const actGetDataInvoiceRequestPaymentById = (id, filter = "student") => {
  return (dispatch) => {
    let config = CONFIG_({
      url: get + "/" + id + "?filter=" + filter,
      method: "GET",
    });
    dispatch({ type: "LOADING_INVOICEREQUESTPAYMENT_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200 || Object.keys(data).length >= 0) {
          return dispatch({
            type: "SUCCESS_INVOICEREQUESTPAYMENT_BYID",
            message: "Success get data",
            item: data,
            code: status,
          });
        }
        return dispatch({
          type: "ERROR_INVOICEREQUESTPAYMENT_INVOICE",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICEREQUESTPAYMENT_INVOICE",
          message: "Network error..",
        });
      });
  };
};

export const actDeleteDataInvoiceRequestPayment = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "DELETE" });
    dispatch({ type: "LOADING_INVOICEREQUESTPAYMENT_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_INVOICEREQUESTPAYMENT_INVOICE",
            message: "Success get data",
          });
        }
        return dispatch({
          type: "ERROR_INVOICEREQUESTPAYMENT_INVOICE",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICEREQUESTPAYMENT_INVOICE",
          message: "Network error..",
        });
      });
  };
};
