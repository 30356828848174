const defaultState = {
  status: "default",
  data: [],
  code: 500,
  message: ""
};

let response = {};
const SiswaBills_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_SISWABILLS_INVOICE":
      response = {
        ...state,
        data: action.data ? action.data : [],
        code: action.code ? action.code : 500,
        status: "success",

        message: action.message ? action.message : ""
      };

      break;

    case "LOADING_SISWABILLS_INVOICE":
      response = {
        ...state,
        status: "loading"
      };
      break;
    case "RESET_SISWABILLS_INVOICE":
      response = {
        ...state,
        status: "default",
        data: []
      };
      break;
    case "ERROR_SISWABILLS_INVOICE":
      response = {
        ...state,
        status: "error",
        message: action.message ? action.message : "",
        code: action.code ? action.code : 500
      };
      break;
    default:
      return {
        ...state
      };
  }

  return response;
};

export default SiswaBills_lib;
