import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { errornotify, successnotify } from "../../components/ToasNotif/Toast";
import { __openModal } from "../Modal";

var get = "/invoice-draft";
var post = "/invoice-draft";
export const actGetDataInvoiceDraft = () => {
  return (dispatch) => {
    let config = CONFIG_({ url: get, method: "GET" });
    dispatch({ type: "LOADING_INVOICEDRAFT_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_INVOICEDRAFT_INVOICE",
            message: "Success get data",
            data: data,
            code: status,
          });
        }
        return dispatch({
          type: "ERROR_INVOICEDRAFT_INVOICE",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICEDRAFT_INVOICE",
          message: "Network error..",
        });
      });
  };
};

export const actPostDataInvoiceDraft = (params) => {
  return (dispatch) => {
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_INVOICEDRAFT_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status, message } = response;
        if (status === 200) {
          successnotify("Invoice draft has been created");
          dispatch(
            __openModal({ modal: "MODAL_ADD_INVOICEDRAFT", open: false })
          );
          return dispatch(actGetDataInvoiceDraft());
        }
        errornotify(message);
        return dispatch({
          type: "ERROR_INVOICEDRAFT_INVOICE",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICEDRAFT_INVOICE",
          message: "Network error..",
        });
      });
  };
};

export const actUpdateDataInvoiceDraft = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, data: params, method: "PUT" });
    dispatch({ type: "LOADING_INVOICEDRAFT_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status, message } = response;
        if (status === 200) {
          successnotify("Invoice draft has been update");
          dispatch(
            __openModal({ modal: "MODAL_EDIT_INVOICEDRAFT", open: false })
          );

          return dispatch(actGetDataInvoiceDraft());
        }
        return dispatch({
          type: "ERROR_INVOICEDRAFT_INVOICE",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICEDRAFT_INVOICE",
          message: "Network error..",
        });
      });
  };
};

export const actDetailDataInvoiceDraft = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "GET" });
    dispatch({ type: "LOADING_INVOICEDRAFT_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_INVOICEDRAFT_INVOICE",
            message: "Success get data",
          });
        }
        return dispatch({
          type: "ERROR_INVOICEDRAFT_INVOICE",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICEDRAFT_INVOICE",
          message: "Network error..",
        });
      });
  };
};

export const actDeleteDataInvoiceDraft = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "DELETE" });
    dispatch({ type: "LOADING_INVOICEDRAFT_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status, message } = response;
        if (status === 200) {
          successnotify("Invoice draft has been deleted");
          dispatch(
            __openModal({ modal: "MODAL_EDIT_INVOICEDRAFT", open: false })
          );

          return dispatch(actGetDataInvoiceDraft());
        }
        return dispatch({
          type: "ERROR_INVOICEDRAFT_INVOICE",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICEDRAFT_INVOICE",
          message: "Network error..",
        });
      });
  };
};
