import React, { Suspense, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

//Layouts
import NonAuthLayout from '../layouts/NonAuthLayout';
import VerticalLayout from '../layouts/index';
//routes
import { authProtectedRoutes, publicRoutes } from './allRoutes';
import { AuthProtected, AccessRoute } from './AuthProtected';
import { ToastContainer } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { actValidateAuth } from '../modules/Auth';
import { Loading, ProviderLoading } from '../components/Loading';

import Error404Page from '../pages/AuthenticationInner/Errors/Cover404';
import TopBarProgress from 'react-topbar-progress-indicator';

const Index = () => {
  const availablePublicRoutesPaths = publicRoutes.map((r) => r.path);
  const availableAuthRoutesPath = authProtectedRoutes.map((r) => r.path);
  const modulProfile = useSelector((state) => state.modulProfile);
  const { statusLogout } = useSelector(({ modulLogout }) => ({
    statusLogout: modulLogout.status,
  }));

  const { statusLogin } = useSelector(({ modulAuth }) => ({
    statusLogin: modulAuth.status,
  }));
  const { auth, loadingPage } = modulProfile;
  const dispatch = useDispatch();

  useEffect(() => {
    if (auth === null) {
      dispatch(actValidateAuth());
    }
  }, [auth, dispatch]);

  useEffect(() => {
    if (statusLogin === 'success') {
      window.location.reload();
    }
  }, [statusLogin]);

  useEffect(() => {
    if (statusLogout === 'success') {
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    }
  }, [statusLogout]);

  return (
    <React.Fragment>
      <Suspense fallback={<React.Fragment />}>
        <ProviderLoading>
          <Loading visible={loadingPage} />
          <ToastContainer />
          <Switch>
            {auth !== null && auth === false ? (
              <Route path={availablePublicRoutesPaths}>
                <NonAuthLayout>
                  <Switch>
                    {publicRoutes.map((route, idx) => (
                      <Route path={route.path} component={route.component} key={idx} exact={true} />
                    ))}
                    <Route path='*' exact={true} component={Error404Page} />
                  </Switch>
                </NonAuthLayout>
              </Route>
            ) : (
              ''
            )}

            {auth !== null && auth === true ? (
              <Route path={availableAuthRoutesPath}>
                <AuthProtected>
                  <VerticalLayout>
                    <Switch>
                      {authProtectedRoutes.map((route, idx) => (
                        <AccessRoute
                          path={route.path}
                          component={route.component}
                          key={idx}
                          exact={true}
                        />
                      ))}

                      <Route path='*' exact={true} component={Error404Page} />
                    </Switch>
                  </VerticalLayout>
                </AuthProtected>
              </Route>
            ) : (
              ''
            )}
          </Switch>
        </ProviderLoading>
      </Suspense>
    </React.Fragment>
  );
};

export default Index;
