import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify } from "../../components/ToasNotif/Toast";
import { __openModal } from "../Modal";

  var get = "/typesetting";
  var post = "/typesetting";
    export const actGetDataTypesetting = () => {
      return (dispatch) => {
        let config = CONFIG_({ url: get, method: "GET" });
        dispatch({ type: "LOADING_TYPESETTING" });
        CONNECTION(config)
          .then((response) => {
            const { status, data} = response;
            if (status === 200) {
              return dispatch({ type: "SUCCESS_TYPESETTING", message: "Success get data", data:data, code:status});
            }
            return dispatch({ type: "ERROR_TYPESETTING", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_TYPESETTING", message: "Network error.." });
          });

      };
    };
    
    export const actPostDataTypesetting = (params) => {
      return (dispatch) => {
        let config = CONFIG_({ url: post, data:params, method: "POST" });
        dispatch({ type: "LOADING_TYPESETTING" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              successnotify('Typesetting has been created');
              dispatch(__openModal({ modal: 'MODAL_ADD_TYPESETTING', open: false}));
              return dispatch(actGetDataTypesetting());
            }
            return dispatch({ type: "ERROR_TYPESETTING", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_TYPESETTING", message: "Network error.." });
          });
      };
    };
    
    export const actUpdateDataTypesetting = (params) => {
      return (dispatch) => {
        const {id} = params
        let config = CONFIG_({ url: post+'/'+id, data:params, method: "PUT" });
        dispatch({ type: "LOADING_TYPESETTING" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              successnotify('Typesetting has been updated');
              dispatch(__openModal({ modal: 'MODAL_EDIT_TYPESETTING', open: false}));
              return dispatch(actGetDataTypesetting());
            }
            return dispatch({ type: "ERROR_TYPESETTING", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_TYPESETTING", message: "Network error.." });
          });
      };
    };
    
    export const actDetailDataTypesetting = (params) => {
      return (dispatch) => {
        const {id} = params
        let config = CONFIG_({ url: post+'/'+id, method: "GET" });
        dispatch({ type: "LOADING_TYPESETTING" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              return dispatch({ type: "SUCCESS_TYPESETTING", message: "Success get data" });
            }
            return dispatch({ type: "ERROR_TYPESETTING", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_TYPESETTING", message: "Network error.." });
          });
      };
    };

      export const actDeleteDataTypesetting = (params) => {
      return (dispatch) => {
        const {id} = params
        let config = CONFIG_({ url: post+'/'+id, method: "DELETE" });
        dispatch({ type: "LOADING_TYPESETTING" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              successnotify('Data has been deleted');
              return dispatch(actGetDataTypesetting());
            }
            return dispatch({ type: "ERROR_TYPESETTING", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_TYPESETTING", message: "Network error.." });
          });
      };
    };