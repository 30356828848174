import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify, warningnotify } from "../../components/ToasNotif/Toast";
import { __openModal } from "../Modal";

var get = "/typekelas";
var post = "/typekelas";
export const actGetDataTypekelas = () => {
  return (dispatch) => {
    let config = CONFIG_({ url: get, method: "GET" });
    dispatch({ type: "LOADING_TYPEKELAS" });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_TYPEKELAS",
            message: "Success get data",
            data: data,
            code: status,
          });
        }
        return dispatch({ type: "ERROR_TYPEKELAS", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_TYPEKELAS",
          message: "Network error..",
        });
      });
  };
};

export const actPostDataTypekelas = (params) => {
  return (dispatch) => {
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_TYPEKELAS" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Class type has been created");
          dispatch(__openModal({ modal: "MODAL_ADD_TYPEKELAS", open: false }));
          return dispatch(actGetDataTypekelas());
        }
        warningnotify(response.message);
        return dispatch({ type: "ERROR_TYPEKELAS", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_TYPEKELAS",
          message: "Network error..",
        });
      });
  };
};

export const actUpdateDataTypekelas = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, data: params, method: "PUT" });
    dispatch({ type: "LOADING_TYPEKELAS" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Class type has been updated");
          dispatch(__openModal({ modal: "MODAL_EDIT_TYPEKELAS", open: false }));
          return dispatch(actGetDataTypekelas());
        }
        warningnotify(response.message);
        return dispatch({ type: "ERROR_TYPEKELAS", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_TYPEKELAS",
          message: "Network error..",
        });
      });
  };
};

export const actDetailDataTypekelas = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "GET" });
    dispatch({ type: "LOADING_TYPEKELAS" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_TYPEKELAS",
            message: "Success get data",
          });
        }
        return dispatch({ type: "ERROR_TYPEKELAS", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_TYPEKELAS",
          message: "Network error..",
        });
      });
  };
};

export const actDeleteDataTypekelas = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "DELETE" });
    dispatch({ type: "LOADING_TYPEKELAS" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Class type has been deleted");
          return dispatch(actGetDataTypekelas());
        }
        warningnotify(response.message);
        return dispatch({ type: "ERROR_TYPEKELAS", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_TYPEKELAS",
          message: "Network error..",
        });
      });
  };
};
