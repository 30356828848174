import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify } from "../../components/ToasNotif/Toast";

var get = "/invoice-draftitem/";
var post = "/invoice-draftitem";
export const actGetDataInvoiceDraftItem = params => {
  const { id } = params;
  return dispatch => {
    let config = CONFIG_({
      url: get + atob(id) + "?filter=draft",
      method: "GET"
    });
    dispatch({ type: "LOADING_INVOICEDRAFTITEM_INVOICE", id: id });
    CONNECTION(config)
      .then(response => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_INVOICEDRAFTITEM_INVOICE",
            message: "Success get data",
            data: data,
            code: status,
            id: id
          });
        }
        return dispatch({
          type: "ERROR_INVOICEDRAFTITEM_INVOICE",
          message: "failed"
        });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICEDRAFTITEM_INVOICE",
          message: "Network error.."
        });
      });
  };
};

export const actPostDataInvoiceDraftItem = params => {
  return dispatch => {
    const { draft } = params;
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({
      type: "LOADING_INVOICEDRAFTITEM_INVOICE",
      id: btoa(draft)
    });
    CONNECTION(config)
      .then(response => {
        const { status, message } = response;
        if (status === 200) {
          successnotify(message);
          return dispatch(actGetDataInvoiceDraftItem({ id: btoa(draft) }));
        }
        return dispatch({
          type: "ERROR_INVOICEDRAFTITEM_INVOICE",
          message: "failed"
        });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICEDRAFTITEM_INVOICE",
          message: "Network error.."
        });
      });
  };
};

export const actUpdateDataInvoiceDraftItem = params => {
  return dispatch => {
    const { id, draft } = params;

    let config = CONFIG_({ url: post + "/" + id, data: params, method: "PUT" });
    console.log(btoa(draft));
    dispatch({
      type: "LOADING_INVOICEDRAFTITEM_INVOICE",
      id: btoa(draft)
    });
    CONNECTION(config)
      .then(response => {
        const { status, message } = response;
        if (status === 200) {
          successnotify(message);
          return dispatch(actGetDataInvoiceDraftItem({ id: btoa(draft) }));
        }
        return dispatch({
          type: "ERROR_INVOICEDRAFTITEM_INVOICE",
          message: "failed"
        });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICEDRAFTITEM_INVOICE",
          message: "Network error.."
        });
      });
  };
};

export const actDetailDataInvoiceDraftItem = params => {
  return dispatch => {
    const { id, draft } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "GET" });
    dispatch({ type: "LOADING_INVOICEDRAFTITEM_INVOICE", id: btoa(draft) });
    CONNECTION(config)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_INVOICEDRAFTITEM_INVOICE",
            message: "Success get data"
          });
        }
        return dispatch({
          type: "ERROR_INVOICEDRAFTITEM_INVOICE",
          message: "failed"
        });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICEDRAFTITEM_INVOICE",
          message: "Network error.."
        });
      });
  };
};

export const actDeleteDataInvoiceDraftItem = params => {
  return dispatch => {
    const { id, draft } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "DELETE" });
    dispatch({ type: "LOADING_INVOICEDRAFTITEM_INVOICE", id: btoa(draft) });
    CONNECTION(config)
      .then(response => {
        const { status, message } = response;
        if (status === 200) {
          successnotify(message);
          return dispatch(actGetDataInvoiceDraftItem({ id: btoa(draft) }));
        }
        return dispatch({
          type: "ERROR_INVOICEDRAFTITEM_INVOICE",
          message: "failed"
        });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICEDRAFTITEM_INVOICE",
          message: "Network error.."
        });
      });
  };
};
