import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify, warningnotify } from "../../components/ToasNotif/Toast";
import { __openModal } from "../Modal";

var get = "/ruangbelajarmapel";
var post = "/ruangbelajarmapel";
export const actGetDataRumbelSubject = (id = "", filter = "rumbel") => {
  return (dispatch) => {
    let config = CONFIG_({
      url: get + "/" + id + "?filter=" + filter,
      method: "GET",
    });
    dispatch({ type: "LOADING_RUMBELSUBJECT_RUMBEL" });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 202) {
          return dispatch({
            type: "SUCCESS_RUMBELSUBJECT_RUMBEL",
            message: "Success get data",
            data: data,
            code: status,
          });
        }
        return dispatch({
          type: "ERROR_RUMBELSUBJECT_RUMBEL",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_RUMBELSUBJECT_RUMBEL",
          message: "Network error..",
        });
      });
  };
};

export const actPostDataRumbelSubject = (params) => {
  return (dispatch) => {
    const { subject } = params;
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_RUMBELSUBJECT_RUMBEL" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Subject has been add to class room");
          dispatch(
            __openModal({ modal: "MODAL_ADD_ROOMSUBJECT", open: false })
          );
          warningnotify(response.message);
          return dispatch(actGetDataRumbelSubject(subject));
        }
        return dispatch({
          type: "ERROR_RUMBELSUBJECT_RUMBEL",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_RUMBELSUBJECT_RUMBEL",
          message: "Network error..",
        });
      });
  };
};

export const actUpdateDataRumbelSubject = (params) => {
  return (dispatch) => {
    const { id, rumbel } = params;
    let config = CONFIG_({ url: post + "/" + id, data: params, method: "PUT" });
    dispatch({ type: "LOADING_RUMBELSUBJECT_RUMBEL" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Subject has been update");
          dispatch(
            __openModal({ modal: "MODAL_EDIT_ROOMSUBJECT", open: false })
          );
          warningnotify(response.message);
          return dispatch(actGetDataRumbelSubject(rumbel));
        }
        return dispatch({
          type: "ERROR_RUMBELSUBJECT_RUMBEL",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_RUMBELSUBJECT_RUMBEL",
          message: "Network error..",
        });
      });
  };
};

export const actDetailDataRumbelSubject = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "GET" });
    dispatch({ type: "LOADING_RUMBELSUBJECT_RUMBEL" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_RUMBELSUBJECT_RUMBEL",
            message: "Success get data",
          });
        }
        return dispatch({
          type: "ERROR_RUMBELSUBJECT_RUMBEL",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_RUMBELSUBJECT_RUMBEL",
          message: "Network error..",
        });
      });
  };
};

export const actDeleteDataRumbelSubject = (params) => {
  return (dispatch) => {
    const { id, subject } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "DELETE" });
    dispatch({ type: "LOADING_RUMBELSUBJECT_RUMBEL" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Subject has been deleted from class room");
          return dispatch(actGetDataRumbelSubject(subject));
        }
        warningnotify(response.message);
        return dispatch({
          type: "ERROR_RUMBELSUBJECT_RUMBEL",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_RUMBELSUBJECT_RUMBEL",
          message: "Network error..",
        });
      });
  };
};
