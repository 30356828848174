import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify, warningnotify } from "../../components/ToasNotif/Toast";
import { __openModal } from "../Modal";

var get = "/typeagama";
var post = "/typeagama";
export const actGetDataTypeagama = () => {
  return (dispatch) => {
    let config = CONFIG_({ url: get, method: "GET" });
    dispatch({ type: "LOADING_TYPEAGAMA" });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_TYPEAGAMA",
            message: "Success get data",
            data: data,
            code: status,
          });
        }
        return dispatch({ type: "ERROR_TYPEAGAMA", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_TYPEAGAMA",
          message: "Network error..",
        });
      });
  };
};

export const actPostDataTypeagama = (params) => {
  return (dispatch) => {
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_TYPEAGAMA" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Type religion has been created");
          dispatch(__openModal({ modal: "MODAL_ADD_TYPEAGAMA", open: false }));
          return dispatch(actGetDataTypeagama());
        }
        warningnotify(response.message);
        return dispatch({ type: "ERROR_TYPEAGAMA", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_TYPEAGAMA",
          message: "Network error..",
        });
      });
  };
};

export const actUpdateDataTypeagama = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, data: params, method: "PUT" });
    dispatch({ type: "LOADING_TYPEAGAMA" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Type religion has been updated");
          dispatch(__openModal({ modal: "MODAL_EDIT_TYPEAGAMA", open: false }));
          return dispatch(actGetDataTypeagama());
        }
        warningnotify(response.message);
        return dispatch({ type: "ERROR_TYPEAGAMA", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_TYPEAGAMA",
          message: "Network error..",
        });
      });
  };
};

export const actDetailDataTypeagama = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "GET" });
    dispatch({ type: "LOADING_TYPEAGAMA" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_TYPEAGAMA",
            message: "Success get data",
          });
        }
        return dispatch({ type: "ERROR_TYPEAGAMA", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_TYPEAGAMA",
          message: "Network error..",
        });
      });
  };
};

export const actDeleteDataTypeagama = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "DELETE" });
    dispatch({ type: "LOADING_TYPEAGAMA" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Type religion has been deleted");
          return dispatch(actGetDataTypeagama());
        }
        warningnotify(response.message);
        return dispatch({ type: "ERROR_TYPEAGAMA", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_TYPEAGAMA",
          message: "Network error..",
        });
      });
  };
};
