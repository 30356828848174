import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify, warningnotify } from "../../components/ToasNotif/Toast";

var get = "/permission-modul";
var post = "/permission-modul";
export const actGetDataPermissionModul = () => {
  return dispatch => {
    let config = CONFIG_({ url: get, method: "GET" });
    dispatch({ type: "LOADING_PERMISSIONMODUL_PERMISSION" });
    CONNECTION(config)
      .then(response => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_PERMISSIONMODUL_PERMISSION",
            message: "Success get data",
            data: data,
            code: status
          });
        }
        return dispatch({
          type: "ERROR_PERMISSIONMODUL_PERMISSION",
          message: "failed"
        });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_PERMISSIONMODUL_PERMISSION",
          message: "Network error.."
        });
      });
  };
};

export const actPostDataPermissionModul = params => {
  return dispatch => {
    const { typeuser } = params;
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_PERMISSIONMODUL_PERMISSION" });
    CONNECTION(config)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          successnotify("Permission modul has been create");
          return dispatch(
            actDetailDataPermissionModul({ id: typeuser }, "typeuser")
          );
        }
        warningnotify("Permission failed to create");

        return dispatch({
          type: "ERROR_PERMISSIONMODUL_PERMISSION",
          message: "failed"
        });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_PERMISSIONMODUL_PERMISSION",
          message: "Network error.."
        });
      });
  };
};

export const actUpdateDataPermissionModul = params => {
  return dispatch => {
    const { id, typeuser } = params;
    let config = CONFIG_({ url: post + "/" + id, data: params, method: "PUT" });
    dispatch({ type: "LOADING_PERMISSIONMODUL_PERMISSION" });
    CONNECTION(config)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          successnotify("Permission modul has been update");
          return dispatch(
            actDetailDataPermissionModul({ id: btoa(typeuser) }, "typeuser")
          );
        }

        warningnotify("Permission modul failed to update");

        return dispatch({
          type: "ERROR_PERMISSIONMODUL_PERMISSION",
          message: "failed"
        });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_PERMISSIONMODUL_PERMISSION",
          message: "Network error.."
        });
      });
  };
};

export const actDetailDataPermissionModul = (params, filter = "") => {
  return dispatch => {
    const { id } = params;
    let query = filter !== "" ? "?filter=" + filter : "";
    let config = CONFIG_({ url: post + "/" + id + query, method: "GET" });
    dispatch({
      type: "LOADING_PERMISSIONMODUL_PERMISSION",
      id: id
    });
    CONNECTION(config)
      .then(response => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_PERMISSIONMODUL_PERMISSION",
            message: "Success get data",
            id: id,
            data: data
          });
        }
        return dispatch({
          type: "ERROR_PERMISSIONMODUL_PERMISSION",
          message: "failed"
        });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_PERMISSIONMODUL_PERMISSION",
          message: "Network error.."
        });
      });
  };
};

export const actDeleteDataPermissionModul = params => {
  return dispatch => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "DELETE" });
    dispatch({ type: "LOADING_PERMISSIONMODUL_PERMISSION" });
    CONNECTION(config)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          successnotify("Permission modul has been delete");

          return dispatch(actDetailDataPermissionModul({ id: id }, "typeuser"));
        }
        return dispatch({
          type: "ERROR_PERMISSIONMODUL_PERMISSION",
          message: "failed"
        });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_PERMISSIONMODUL_PERMISSION",
          message: "Network error.."
        });
      });
  };
};
