const defaultState = {
  loadingPage: true,
  loadingSend: true,
  data: [],
  code: 500,
  auth: null,
  message: ""
};

let response = {};
const Profile_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_PROFILE_AUTH":
      response = {
        ...state,
        data: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        auth: true,
        message: action.message ? action.message : ""
      };

      break;
    case "LOADING_PROFILE_AUTH":
      response = {
        ...state,
        loadingPage: true,
        auth: null,

        loadingSend: true
      };
      break;
    case "ERROR_PROFILE_AUTH":
      response = {
        ...state,
        loadingPage: false,
        auth: false,
        loadingSend: false,
        message: action.message ? action.message : "",
        code: action.code ? action.code : 500
      };
      break;
    default:
      return {
        ...state
      };
  }

  return response;
};

export default Profile_lib;
