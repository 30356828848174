import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify, warningnotify } from "../../components/ToasNotif/Toast";
import { __openModal } from "../Modal";

var get = "/siswa-historyeducation";
var post = "/siswa-historyeducation";
export const actGetDataHistoryEduSiswa = (id, filter = "student") => {
  return (dispatch) => {
    let config = CONFIG_({
      url: get + "/" + id + "?filter=" + filter,
      method: "GET",
    });
    dispatch({ type: "LOADING_HISTORYEDUSISWA", id: id });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_HISTORYEDUSISWA",
            message: "Success get data",
            data: data,
            code: status,
            id: String(id),
          });
        }
        return dispatch({ type: "ERROR_HISTORYEDUSISWA", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_HISTORYEDUSISWA",
          message: "Network error..",
        });
      });
  };
};

export const actPostDataHistoryEduSiswa = (params) => {
  return (dispatch) => {
    const { siswa } = params;
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_HISTORYEDUSISWA" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("History education has been created");
          dispatch(
            __openModal({ modal: "MODAL_ADD_HISTORYEDUSISWA", open: false })
          );
          return dispatch(actGetDataHistoryEduSiswa(String(siswa)));
        }
        warningnotify(response.message);
        return dispatch({ type: "ERROR_HISTORYEDUSISWA", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_HISTORYEDUSISWA",
          message: "Network error..",
        });
      });
  };
};

export const actUpdateDataHistoryEduSiswa = (params) => {
  return (dispatch) => {
    const { id, siswa } = params;
    let config = CONFIG_({ url: post + "/" + id, data: params, method: "PUT" });
    dispatch({ type: "LOADING_HISTORYEDUSISWA" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("History education has been update");
          dispatch(
            __openModal({ modal: "MODAL_EDIT_HISTORYEDUSISWA", open: false })
          );
          return dispatch(actGetDataHistoryEduSiswa(String(siswa)));
        }
        warningnotify(response.message);
        return dispatch({ type: "ERROR_HISTORYEDUSISWA", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_HISTORYEDUSISWA",
          message: "Network error..",
        });
      });
  };
};

export const actDetailDataHistoryEduSiswa = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "GET" });
    dispatch({ type: "LOADING_HISTORYEDUSISWA" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_HISTORYEDUSISWA",
            message: "Success get data",
          });
        }
        return dispatch({ type: "ERROR_HISTORYEDUSISWA", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_HISTORYEDUSISWA",
          message: "Network error..",
        });
      });
  };
};

export const actDeleteDataHistoryEduSiswa = (params) => {
  return (dispatch) => {
    const { id, siswa } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "DELETE" });
    dispatch({ type: "LOADING_HISTORYEDUSISWA" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("History education has been Deleted");
          return dispatch(actGetDataHistoryEduSiswa(siswa));
        }
        warningnotify(response.message);
        return dispatch({ type: "ERROR_HISTORYEDUSISWA", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_HISTORYEDUSISWA",
          message: "Network error..",
        });
      });
  };
};
