import { combineReducers } from "redux";

import {
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Calendar,
  chat,
  Projects,
  Ecommerce,
  Tasks,
  changeNumber,
  Crypto,
  Tickets,
  Crm,
  Mailbox,
  Invoice,
  DashboardAnalytics,
  DashboardEcommerce,
  DashboardCRM,
  DashboardCrypto,
  DashboardNFT,
  DashboardProject,
  Team,
} from "../../vendor/store/reducers";
import { modulAuth, modulProfile } from "./Auth";

import { modals } from "./Modal";
import { modulTypeuser } from "./Typeuser";
import { modulUser } from "./User";
import { modulTypeagama } from "./Typeagama";
import { modulTypeabsensi } from "./Typeabsensi";
import { modulTypetatib } from "./Typetatib";
import { modulTypebook } from "./Typebook";
import { modulTypepenerbit } from "./Typepenerbit";
import { modulTypeitem } from "./Typeitem";
import { modulTypePendidikan } from "./Typependidikan";
import { modulTypestatus } from "./Typestatus";
import { modulTypepayment } from "./Typepayment";
import { modulTypekelas } from "./Typekelas";
import { modulTyperegistration } from "./Typeregistration";
import { modulTypepelajaran } from "./Typepelajaran";
import { modulTypeinvoice } from "./Typeinvoice";
import { modulProvinsi } from "./Provisi";
import { modulKabupaten } from "./Kabupaten";
import { modulBook } from "./Book";
import { modulMenu } from "./Menu";

/** SISWA REDUCER */
import {
  modulSiswa,
  modulHistoryEduSiswa,
  modulPersonalInfoSiswa,
  modulSettingPayment,
  modulSiswaLogin,
  modulSiswaNotification,
  modulBulkSiswa,
} from "./Siswa";

/** INVOICE REDUCER*/
import {
  modulInvoice,
  modulInvoiceBill,
  modulInvoiceDraft,
  modulInvoiceDraftItem,
  modulInvoiceRequestPayment,
} from "./Invoice";
import { modulTypefamily } from "./Typefamily";

/**GURU REDUCER */
import { modulGuru, modulGuruHistoryEdu, modulGuruPersonalInfo } from "./Guru";
import { modulPelajaran } from "./Pelajaran";
import { modulMatapelajaran } from "./Matapelajaran";

import {
  modulPayment,
  modulPaymentXendit,
  modulPaymentInternal,
} from "./Payment";

import { modulXenditBankAccount } from "./Xendit";

import { modulSetting } from "./Setting";
import { modulTypesetting } from "./Typesetting";
import { modulVasetting } from "./Vasetting";

/** RUMBEL REDUCER */
import {
  modulRumbel,
  modulRumbelMember,
  modulRumbelPelajaran,
  modulRumbelSubject,
  modulBulkMember,
} from "./Rumbel";

import { modulSummaryDashboard } from "./Dashboard";
import { modulModule } from "./Modul";
import { modulPermissionMenu, modulPermissionModul } from "./Permission";
import { modulPaymentSummary } from "./Payment";
import { modulLogout } from "./Logout";
const rootReducer = combineReducers({
  // vendor reducer
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  Calendar,
  chat,
  Projects,
  Ecommerce,
  Tasks,
  changeNumber,
  Crypto,
  Tickets,
  Crm,
  Invoice,
  Mailbox,
  DashboardAnalytics,
  DashboardCRM,
  DashboardEcommerce,
  DashboardCrypto,
  DashboardProject,
  DashboardNFT,
  Team,
  // own Reducer
  modulAuth,
  modulProfile,
  modals,
  modulTypeuser,
  modulUser,
  modulTypeagama,
  modulTypeabsensi,
  modulTypetatib,
  modulTypebook,
  modulTypepenerbit,
  modulTypeitem,
  modulTypePendidikan,
  modulTypeinvoice,
  modulTypestatus,
  modulTypepayment,
  modulTypekelas,
  modulTyperegistration,
  modulTypepelajaran,
  modulTypefamily,
  modulMenu,
  /** MODUL SISWA */
  modulSiswa,
  modulPersonalInfoSiswa,
  modulHistoryEduSiswa,
  modulSettingPayment,
  modulSiswaLogin,
  modulSiswaNotification,
  modulBulkSiswa,

  /** MODUL GURU */
  modulGuru,
  modulGuruPersonalInfo,
  modulGuruHistoryEdu,

  modulProvinsi,
  modulKabupaten,
  modulBook,
  modulInvoice,
  modulPelajaran,
  modulMatapelajaran,
  modulPayment,
  modulPaymentInternal,
  modulRumbel,
  modulInvoiceBill,
  modulInvoiceRequestPayment,
  modulInvoiceDraft,
  modulInvoiceDraftItem,
  modulXenditBankAccount,
  modulPaymentXendit,

  /**MODUL RUMBEL */
  modulRumbelMember,
  modulRumbelSubject,
  modulRumbelPelajaran,
  modulBulkMember,

  modulSetting,
  modulTypesetting,
  modulVasetting,
  modulSummaryDashboard,
  modulModule,
  modulPermissionMenu,
  modulPermissionModul,
  modulPaymentSummary,
  modulLogout,
});

export default rootReducer;
