const defaultState = {
  status: "default",
  data: [],
  item: [],
  code: 500,
  message: "",
};

let response = {};
const InvoiceRequestPayment_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_INVOICEREQUESTPAYMENT_INVOICE":
      response = {
        ...state,
        data: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        status: "success",

        message: action.message ? action.message : "",
      };

      break;
    case "SUCCESS_INVOICEREQUESTPAYMENT_BYID":
      response = {
        ...state,
        item: action.item ? action.item : {},
        code: action.code ? action.code : 500,
        status: "success get item",

        message: action.message ? action.message : "",
      };

      break;
    case "SUCCESS_INVOICEREQUESTPAYMENT_ALLOCATION":
      response = {
        ...state,
        data: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        status: "success put allocation",

        message: action.message ? action.message : "",
      };

      break;
    case "LOADING_INVOICEREQUESTPAYMENT_INVOICE":
      response = {
        ...state,
        status: "loading",
      };
      break;
    case "ERROR_INVOICEREQUESTPAYMENT_INVOICE":
      response = {
        ...state,
        status: "error",
        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
      };
  }

  return response;
};

export default InvoiceRequestPayment_lib;
