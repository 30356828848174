import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify } from "../../components/ToasNotif/Toast";
import { __openModal } from "../Modal";

  var get = "/typepenerbit";
  var post = "/typepenerbit";
    export const actGetDataTypepenerbit = () => {
      return (dispatch) => {
        let config = CONFIG_({ url: get, method: "GET" });
        dispatch({ type: "LOADING_TYPEPENERBIT" });
        CONNECTION(config)
          .then((response) => {
            const { status, data} = response;
            if (status === 200) {
              return dispatch({ type: "SUCCESS_TYPEPENERBIT", message: "Success get data", 
              data:data, 
              code:status});
            }
            return dispatch({ type: "ERROR_TYPEPENERBIT", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_TYPEPENERBIT", message: "Network error.." });
          });

      };
    };
    
    export const actPostDataTypepenerbit = (params) => {
      return (dispatch) => {
        let config = CONFIG_({ url: post, data:params, method: "POST" });
        dispatch({ type: "LOADING_TYPEPENERBIT" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              successnotify('Publisher type has been created');
              dispatch(__openModal({ modal: 'MODAL_ADD_TYPEPENERBIT', open: false}));
              return dispatch(actGetDataTypepenerbit());
            }
            return dispatch({ type: "ERROR_TYPEPENERBIT", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_TYPEPENERBIT", message: "Network error.." });
          });
      };
    };
    
    export const actUpdateDataTypepenerbit = (params) => {
      return (dispatch) => {
        const {id} = params
        let config = CONFIG_({ url: post+'/'+id, data:params, method: "PUT" });
        dispatch({ type: "LOADING_TYPEPENERBIT" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              successnotify('Publisher type has been updated');
              dispatch(__openModal({ modal: 'MODAL_EDIT_TYPEPENERBIT', open: false}));
              return dispatch(actGetDataTypepenerbit());
            }
            return dispatch({ type: "ERROR_TYPEPENERBIT", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_TYPEPENERBIT", message: "Network error.." });
          });
      };
    };
    
    export const actDetailDataTypepenerbit = (params) => {
      return (dispatch) => {
        const {id} = params
        let config = CONFIG_({ url: post+'/'+id, method: "GET" });
        dispatch({ type: "LOADING_TYPEPENERBIT" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              return dispatch({ type: "SUCCESS_TYPEPENERBIT", message: "Success get data" });
            }
            return dispatch({ type: "ERROR_TYPEPENERBIT", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_TYPEPENERBIT", message: "Network error.." });
          });
      };
    };

    export const actDeleteDataTypepenerbit = (params) => {
      return (dispatch) => {
        const {id} = params
        let config = CONFIG_({ url: post+'/'+id, method: "DELETE" });
        dispatch({ type: "LOADING_TYPEPENERBIT" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              successnotify('Data has been deleted');
              return dispatch(actGetDataTypepenerbit());
            }
            return dispatch({ type: "ERROR_TYPEPENERBIT", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_TYPEPENERBIT", message: "Network error.." });
          });
      };
    };