import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify } from "../../components/ToasNotif/Toast";
import { __openModal } from "../Modal";

  var get = "/typebook";
  var post = "/typebook";
    export const actGetDataTypebook = () => {
      return (dispatch) => {
        let config = CONFIG_({ url: get, method: "GET" });
        dispatch({ type: "LOADING_TYPEBOOK" });
        CONNECTION(config)
          .then((response) => {
            const { status, data} = response;
            if (status === 200) {
              return dispatch({ type: "SUCCESS_TYPEBOOK", message: "Success get data", 
              data:data, 
              code:status});
            }
            return dispatch({ type: "ERROR_TYPEBOOK", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_TYPEBOOK", message: "Network error.." });
          });

      };
    };
    
    export const actPostDataTypebook = (params) => {
      return (dispatch) => {
        let config = CONFIG_({ url: post, data:params, method: "POST" });
        dispatch({ type: "LOADING_TYPEBOOK" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              successnotify('Book Type has been created');
              dispatch(__openModal({ modal: 'MODAL_ADD_TYPEBOOK', open: false}));
              return dispatch(actGetDataTypebook());
            }
            return dispatch({ type: "ERROR_TYPEBOOK", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_TYPEBOOK", message: "Network error.." });
          });
      };
    };
    
    export const actUpdateDataTypebook = (params) => {
      return (dispatch) => {
        const {id} = params
        let config = CONFIG_({ url: post+'/'+id, data:params, method: "PUT" });
        dispatch({ type: "LOADING_TYPEBOOK" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              successnotify('Book type has been updated')
              dispatch(__openModal({ modal: 'MODAL_EDIT_TYPEBOOK', open: false}));
              return dispatch(actGetDataTypebook());
            }
            return dispatch({ type: "ERROR_TYPEBOOK", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_TYPEBOOK", message: "Network error.." });
          });
      };
    };
    
    export const actDetailDataTypebook = (params) => {
      return (dispatch) => {
        const {id} = params
        let config = CONFIG_({ url: post+'/'+id, method: "GET" });
        dispatch({ type: "LOADING_TYPEBOOK" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              return dispatch({ type: "SUCCESS_TYPEBOOK", message: "Success get data" });
            }
            return dispatch({ type: "ERROR_TYPEBOOK", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_TYPEBOOK", message: "Network error.." });
          });
      };
    };

    export const actDeleteDataTypebook = (params) => {
      return (dispatch) => {
        const {id} = params
        let config = CONFIG_({ url: post+'/'+id, method: "DELETE" });
        dispatch({ type: "LOADING_TYPEBOOK" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              successnotify('Data has been deleted');
              dispatch(actGetDataTypebook());
            }
            return dispatch({ type: "ERROR_TYPEBOOK", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_TYPEBOOK", message: "Network error.." });
          });
      };
    };