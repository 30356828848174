const defaultState = {
  loadingPage: true,
  loadingSend: true,
  data: [],
  code: 500,
  message: "",
  status: "default"
};

let response = {};
const Auth_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_AUTH_AUTH":
      response = {
        ...state,
        restData: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
        status: "success"
      };

      break;
    case "LOADING_AUTH_AUTH":
      response = {
        ...state,
        loadingPage: true,
        loadingSend: true,
        status: "loading"
      };
      break;
    case "ERROR_AUTH_AUTH":
      response = {
        ...state,
        loadingPage: false,
        loadingSend: false,
        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
        status: "error"
      };
      break;
    default:
      return {
        ...state,
        loadingPage: false,
        loadingSend: false
      };
  }

  return response;
};

export default Auth_lib;
