import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify, warningnotify } from "../../components/ToasNotif/Toast";
import { __openModal } from "../Modal";

var get = "/typeitem";
var post = "/typeitem";
export const actGetDataTypeitem = () => {
  return (dispatch) => {
    let config = CONFIG_({ url: get, method: "GET" });
    dispatch({ type: "LOADING_TYPEITEM" });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_TYPEITEM",
            message: "Success get data",
            data: data,
            code: status,
          });
        }
        return dispatch({ type: "ERROR_TYPEITEM", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: "ERROR_TYPEITEM", message: "Network error.." });
      });
  };
};

export const actPostDataTypeitem = (params) => {
  return (dispatch) => {
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_TYPEITEM" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Item type has been created");
          dispatch(__openModal({ modal: "MODAL_ADD_TYPEITEM", open: false }));
          return dispatch(actGetDataTypeitem());
        }
        warningnotify(response.message);
        return dispatch({ type: "ERROR_TYPEITEM", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: "ERROR_TYPEITEM", message: "Network error.." });
      });
  };
};

export const actUpdateDataTypeitem = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, data: params, method: "PUT" });
    dispatch({ type: "LOADING_TYPEITEM" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Item type has been updated");
          dispatch(__openModal({ modal: "MODAL_EDIT_TYPEITEM", open: false }));
          return dispatch(actGetDataTypeitem());
        }
        warningnotify(response.message);
        return dispatch({ type: "ERROR_TYPEITEM", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: "ERROR_TYPEITEM", message: "Network error.." });
      });
  };
};

export const actDetailDataTypeitem = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "GET" });
    dispatch({ type: "LOADING_TYPEITEM" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_TYPEITEM",
            message: "Success get data",
          });
        }
        return dispatch({ type: "ERROR_TYPEITEM", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: "ERROR_TYPEITEM", message: "Network error.." });
      });
  };
};

export const actDeleteDataTypeitem = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "DELETE" });
    dispatch({ type: "LOADING_TYPEITEM" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Item type has been deleted");
          return dispatch(actGetDataTypeitem());
        }
        warningnotify(response.message);
        return dispatch({ type: "ERROR_TYPEITEM", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({ type: "ERROR_TYPEITEM", message: "Network error.." });
      });
  };
};
