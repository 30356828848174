import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify, warningnotify } from "../../components/ToasNotif/Toast";
import { __openModal } from "../Modal";

var get = "/invoice";
var post = "/invoice";
export const actGetDataInvoice = () => {
  return (dispatch) => {
    let config = CONFIG_({ url: get, method: "GET" });
    dispatch({ type: "LOADING_INVOICE_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_INVOICE_INVOICE",
            message: "Success get data",
            data: data,
            code: status,
          });
        }
        return dispatch({ type: "ERROR_INVOICE_INVOICE", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICE_INVOICE",
          message: "Network error..",
        });
      });
  };
};
export const actGetDataInvoiceBySiswa = (id, filter = "student") => {
  return (dispatch) => {
    let config = CONFIG_({
      url: get + "/" + id + "?filter=" + filter,
      method: "GET",
    });
    dispatch({ type: "LOADING_INVOICE_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_INVOICE_BYID",
            message: "Success get data siswa by id",
            data: data,
            code: status,
          });
        }
        return dispatch({ type: "ERROR_INVOICE_INVOICE", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICE_INVOICE",
          message: "Network error..",
        });
      });
  };
};

/**
 *
 * @description
 * Fixed invoice
 */
export const actPostDataInvoice = (params) => {
  return (dispatch) => {
    let config = CONFIG_({
      url: post + "/fixed",
      data: params,
      method: "POST",
    });
    dispatch({ type: "LOADING_INVOICE_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status, message } = response;
        if (status === 201) {
          successnotify("Invoice fix has been created");

          dispatch({
            type: "RESET_SISWABILLS_INVOICE",
            message: "Success post data",
          });
          return dispatch({
            type: "SUCCESS_INVOICECREATE_INVOICE",
            message: "Success post data",
          });
          //  dispatch(actGetDataInvoice());
        }
        warningnotify(message);

        return dispatch({ type: "ERROR_INVOICE_INVOICE", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICE_INVOICE",
          message: "Network error..",
        });
      });
  };
};

/**
 *
 * @description
 * Flexible invoice
 */
export const actPostDataInvoiceFlex = (params) => {
  return (dispatch) => {
    let config = CONFIG_({
      url: post,
      data: params,
      method: "POST",
    });
    dispatch({ type: "LOADING_INVOICE_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status, message } = response;
        if (status === 201) {
          successnotify("Invoice flex has been created");

          return dispatch({
            type: "SUCCESS_INVOICECREATE_INVOICE",
            message: "Success post data",
          });
          //  dispatch(actGetDataInvoice());
        }
        warningnotify(message);

        return dispatch({ type: "ERROR_INVOICE_INVOICE", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICE_INVOICE",
          message: "Network error..",
        });
      });
  };
};

export const actUpdateDataInvoice = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, data: params, method: "PUT" });
    dispatch({ type: "LOADING_INVOICE_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_INVOICE_INVOICE",
            message: "Success put data",
          });
        }
        return dispatch({ type: "ERROR_INVOICE_INVOICE", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICE_INVOICE",
          message: "Network error..",
        });
      });
  };
};

export const actDetailDataInvoice = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "GET" });
    dispatch({ type: "LOADING_INVOICE_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "DETAIL_INVOICE_INVOICE",
            message: "Success get data",
            detail: Array.isArray(data) ? data[0] : data,
            code: status,
            id: id,
          });
        }
        return dispatch({ type: "ERROR_INVOICE_INVOICE", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICE_INVOICE",
          message: "Network error..",
        });
      });
  };
};
export const actDetailDataInvoiceFast = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "GET" });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "DETAIL_INVOICE_INVOICE",
            message: "Success get data",
            detail: Array.isArray(data) ? data[0] : data,
            code: status,
            id: id,
          });
        }
        return dispatch({ type: "ERROR_INVOICE_INVOICE", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICE_INVOICE",
          message: "Network error..",
        });
      });
  };
};

export const actDeleteDataInvoice = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "DELETE" });
    dispatch({ type: "LOADING_INVOICE_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status, message } = response;
        if (status === 200) {
          successnotify("Invoice has been deleted");
          return dispatch(actGetDataInvoice());
        }
        dispatch({ type: "ERROR_INVOICE_INVOICE", message: "failed" });
        return warningnotify(message);
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICE_INVOICE",
          message: "Network error..",
        });
      });
  };
};

export const actPostDataFilterInvoice = (params) => {
  return (dispatch) => {
    let config = CONFIG_({
      url: post + "/filter",
      data: params,
      method: "POST",
    });
    dispatch({ type: "LOADING_INVOICE_INVOICE" });
    CONNECTION(config)
      .then((response) => {
        const { status, message, data } = response;
        if (status === 200) {
          successnotify("Invoice has been filtered");
          dispatch(__openModal({ modal: "MODAL_FILTER_INVOICE", open: false }));
          return dispatch({
            type: "SUCCESS_INVOICEFILTER_INVOICE",
            message: "Success filter data",
            data: data,
            code: status,
          });
        }
        warningnotify(message);

        return dispatch({ type: "ERROR_INVOICE_INVOICE", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_INVOICE_INVOICE",
          message: "Network error..",
        });
      });
  };
};
