const defaultState = {
  status: "default",
  data: [],
  detail: [],
  code: 500,
  message: "",
};

let response = {};
const Guru_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_GURU":
      response = {
        ...state,
        data: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        status: "success",

        message: action.message ? action.message : "",
      };

      break;
    case "SUCCESS_DETAIL_GURU":
      response = {
        ...state,
        detail: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        status: "success",

        message: action.message ? action.message : "",
      };

      break;
    case "LOADING_GURU":
      response = {
        ...state,
        status: "loading",
      };
      break;
    case "ERROR_GURU":
      response = {
        ...state,
        status: "error",
        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
      };
  }

  return response;
};

export default Guru_lib;
