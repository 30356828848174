import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";

var get = "/x-virtualaccount/xen";
export const actGetDataBankAccount = () => {
  return (dispatch) => {
    let config = CONFIG_({ url: get, method: "GET" });
    dispatch({ type: "LOADING_BANKACCOUNT_XENDIT" });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_BANKACCOUNT_XENDIT",
            message: "Success get data",
            data: data,
            code: status,
          });
        }
        return dispatch({
          type: "ERROR_BANKACCOUNT_XENDIT",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_BANKACCOUNT_XENDIT",
          message: "Network error..",
        });
      });
  };
};
