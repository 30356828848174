const defaultState = {
  status: "default",
  data: [],
  code: 500,
  message: "",
  id: ""
};

let response = {};
const InvoiceDraftItem_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_INVOICEDRAFTITEM_INVOICE":
      response = {
        ...state,
        data: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        status: "success",
        id: action.id ? action.id : "",
        message: action.message ? action.message : ""
      };

      break;
    case "LOADING_INVOICEDRAFTITEM_INVOICE":
      response = {
        ...state,
        status: "loading",
        id: action.id ? action.id : ""
      };
      break;
    case "ERROR_INVOICEDRAFTITEM_INVOICE":
      response = {
        ...state,
        status: "error",
        message: action.message ? action.message : "",
        code: action.code ? action.code : 500
      };
      break;
    default:
      return {
        ...state
      };
  }

  return response;
};

export default InvoiceDraftItem_lib;
