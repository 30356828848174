import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify, warningnotify } from "../../components/ToasNotif/Toast";
import { __openModal } from "../Modal";
import { actDetailDataInvoiceFast } from "../Invoice";

var get = "/xen-payment";
var post = "/xen-payment";

export const actPostDataXendit = params => {
  return dispatch => {
    const { invoice_id } = params;
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_XENDIT_PAYMENT" });
    CONNECTION(config)
      .then(response => {
        const { status, message } = response;
        if (status === 200) {
          successnotify(message);
          dispatch(
            __openModal({ modal: "MODAL_ADD_PAYMENTXENDIT", open: false })
          );
          dispatch({ type: "SUCCESS_XENDIT_PAYMENT" });
          return dispatch(actDetailDataInvoiceFast({ id: invoice_id }));
        }
        warningnotify(message);
        return dispatch({ type: "ERROR_XENDIT_PAYMENT", message: "failed" });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_XENDIT_PAYMENT",
          message: "Network error.."
        });
      });
  };
};

export const actPostDataXenditConfirm = params => {
  return dispatch => {
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_XENDIT_PAYMENT" });
    CONNECTION(config)
      .then(response => {
        const { status } = response;
        if (status === 201) {
          return dispatch({
            type: "SUCCESS_XENDIT_PAYMENT",
            message: "Success post data"
          });
        }
        return dispatch({ type: "ERROR_XENDIT_PAYMENT", message: "failed" });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_XENDIT_PAYMENT",
          message: "Network error.."
        });
      });
  };
};
