import { CONNECTION } from "../../config/Connections";
import { CONFIG_, __userID } from "../../config/Config";
import { successnotify, warningnotify } from "../../components/ToasNotif/Toast";

var post = "/auth/logout";

export const actPostDataLogout = params => {
  return dispatch => {
    let config = CONFIG_({
      url: post,
      data: params,
      method: "POST",
      headers: {
        Authorization: `Bearer ` + __userID()
      }
    });
    dispatch({ type: "LOADING_LOGOUT_LOGOUT" });
    warningnotify("Please wait...");
    CONNECTION(config)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          successnotify("Logout Success!");
          return dispatch({
            type: "SUCCESS_LOGOUT_LOGOUT",
            message: "Success post data"
          });
        }
        successnotify("Logout Success!");
        return dispatch({ type: "ERROR_LOGOUT_LOGOUT", message: "failed" });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_LOGOUT_LOGOUT",
          message: "Network error.."
        });
      });
  };
};
