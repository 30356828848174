import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const Navdata = () => {
  const history = useHistory();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Dashboard");
  const [isOption, setIsOptions] = useState(false);
  const [isSetting, setIsSetting] = useState(false);
  const [isInvoice, setIsInvoice] = useState(false);
  const [isPayment, setIsPayment] = useState(false);
  const [isBulk, setIsBulk] = useState(false);

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");

    if (iscurrentState !== "options") {
      setIsOptions(false);
    }
    if (iscurrentState !== "setting") {
      setIsSetting(false);
    }
    if (iscurrentState !== "invoice") {
      setIsInvoice(false);
    }
  }, [history, isOption, iscurrentState, isSetting]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "dashboard",
      label: "Dashboards",
      icon: "bx bx-home fs-20",
      link: "/",
    },
    {
      id: "siswapage",
      label: "Students",
      icon: "ri-user-star-fill fs-20",
      link: "/siswa",
    },
    {
      id: "gurupage",
      label: "Teachers",
      icon: " ri-file-user-fill fs-20",
      link: "/guru",
    },
    {
      id: "payment",
      label: "Payment",
      icon: " ri-wallet-fill fs-20",
      link: "/",
      stateVariables: isPayment,
      click: function (e) {
        e.preventDefault();
        setIsPayment(!isPayment);
        setIscurrentState("payment");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "paymentList",
          label: "List Payment",
          link: "/payment",
          parentId: "payment",
        },
      ],
    },
    {
      id: "invoice",
      label: "Invoices",
      icon: "ri-file-text-line fs-20",
      link: "/",
      stateVariables: isInvoice,
      click: function (e) {
        e.preventDefault();
        setIsInvoice(!isInvoice);
        setIscurrentState("invoice");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "invoicelist",
          label: "Invoice List",
          link: "/invoice",
          parentId: "invoice",
        },
        {
          id: "addinvoice",
          label: "Add Invoice (Fixed)",
          link: "/addinvoice",
          parentId: "invoice",
        },
        {
          id: "addinvoice",
          label: "Add Invoice (Flex)",
          link: "/addinvoiceflex",
          parentId: "invoice",
        },
        {
          id: "invoicedraft",
          label: "Draft Invoice",
          link: "/invoicedraft",
          parentId: "invoice",
        },
      ],
    },
    {
      id: "rumbel",
      label: "Class Room",
      icon: "ri-community-fill fs-20",
      link: "/rumbel",
    },
    {
      id: "notification",
      label: "Notification",
      icon: "ri-notification-3-line fs-20",
      link: "/siswanotification",
    },
    // {
    //   id: "bulk",
    //   label: "Bulk",
    //   icon: " ri-wallet-fill fs-20",
    //   link: "/",
    //   stateVariables: isBulk,
    //   click: function (e) {
    //     e.preventDefault();
    //     setIsBulk(!isBulk);
    //     setIscurrentState("bulk");
    //     updateIconSidebar(e);
    //   },
    //   subItems: [
    //     {
    //       id: "bulkrumbelmember",
    //       label: "Bulk Class Room Member",
    //       link: "/bulkrumbelmember",
    //       parentId: "bulk",
    //     },
    //     {
    //       id: "bulkstudent",
    //       label: "Bulk Student",
    //       link: "/bulksiswa",
    //       parentId: "bulk",
    //     },
    //   ],
    // },
    {
      label: "Master",
      isHeader: true,
    },
    {
      id: "option",
      label: "Options",
      icon: " bx bx-book fs-20",
      link: "/",
      stateVariables: isOption,
      click: function (e) {
        e.preventDefault();
        setIsOptions(!isOption);
        setIscurrentState("options");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "lesson",
          label: "Lesson",
          link: "/pelajaran",
          parentId: "option",
        },
        {
          id: "subjects",
          label: "Subjects",
          link: "/matapelajaran",
          parentId: "option",
        },
        {
          id: "classtype",
          label: "Class Type",
          link: "/typekelas",
          parentId: "option",
        },
        {
          id: "lessontype",
          label: "Lesson Type",
          link: "/typepelajaran",
          parentId: "option",
        },
        {
          id: "typeabsensi",
          label: "Attendence Type",
          link: "/typeabsensi",
          parentId: "option",
        },
        {
          id: "typeagama",
          label: "Religion Type",
          link: "/typeagama",
          parentId: "option",
        },
        {
          id: "typetatib",
          label: "Regulation Type",
          link: "/typetatib",
          parentId: "option",
        },
        {
          id: "typeitem",
          label: "Item Type",
          link: "/typeitem",
          parentId: "option",
        },

        {
          id: "typeinvoice",
          label: "Invoice Type",
          link: "/typeinvoice",
          parentId: "setting",
        },
        {
          id: "typependidikan",
          label: "Education Type",
          link: "/typependidikan",
          parentId: "setting",
        },
        {
          id: "typestatus",
          label: "Status Type",
          link: "/typestatus",
          parentId: "setting",
        },
        {
          id: "typefamily",
          label: "Family Type",
          link: "/typefamily",
          parentId: "setting",
        },
        {
          id: "typepayment",
          label: "Payment Type",
          link: "/typepayment",
          parentId: "setting",
        },
        {
          id: "typeregistration",
          label: "Registration Type",
          link: "/typeregistration",
          parentId: "setting",
        },
        {
          id: "district",
          label: "District",
          link: "/kabupaten",
          parentId: "option",
        },
        {
          id: "province",
          label: "Province",
          link: "/provinsi",
          parentId: "option",
        },
      ],
    },
    {
      label: "Application",
      isHeader: true,
    },
    {
      id: "user",
      label: "User",
      icon: " bx bxs-user-account fs-20",
      link: "/user",
    },
    {
      id: "setting",
      label: "Setting",
      icon: "bx bx-sun",
      link: "/",
      stateVariables: isSetting,
      click: function (e) {
        e.preventDefault();
        setIsSetting(!isSetting);
        setIscurrentState("setting");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "menu",
          label: "Menu",
          link: "/menu",
          parentId: "setting",
        },
        {
          id: "modul",
          label: "Modul",
          link: "/modul",
          parentId: "setting",
        },
        {
          id: "general",
          label: "General",
          link: "/",
          parentId: "setting",
        },
        {
          id: "setting",
          label: "Setting",
          link: "/setting",
          parentId: "setting",
        },
        {
          id: "typesetting",
          label: "Setting Type",
          link: "/typesetting",
          parentId: "setting",
        },
        {
          id: "typeuser",
          label: "User Type",
          link: "/typeuser",
          parentId: "setting",
        },
        {
          id: "vasetting",
          label: "Va Setting",
          link: "/vasetting",
          parentId: "setting",
        },
      ],
    },
    // {
    //   id: "logout",
    //   label: "Log Out fs-20",
    //   icon: "bx bx-power-off",
    //   link: "/"
    // }
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
