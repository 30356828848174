import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify, warningnotify } from "../../components/ToasNotif/Toast";
import { __openModal } from "../Modal";

var get = "/payment";
var post = "/payment";
export const actGetDataPayment = () => {
  return (dispatch) => {
    let config = CONFIG_({ url: get, method: "GET" });
    dispatch({ type: "LOADING_PAYMENT_PAYMENT" });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_PAYMENT_PAYMENT",
            message: "Success get data",
            data: data,
            code: status,
          });
        }
        return dispatch({ type: "ERROR_PAYMENT_PAYMENT", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_PAYMENT_PAYMENT",
          message: "Network error..",
        });
      });
  };
};
export const actGetDataPaymentBySiswa = (id, filter = "student") => {
  return (dispatch) => {
    let config = CONFIG_({
      url: get + "/" + id + "?filter=" + filter,
      method: "GET",
    });
    dispatch({ type: "LOADING_PAYMENT_PAYMENT" });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_PAYMENT_SISWA",
            message: "Success get data",
            data: data,
            id: id,
            code: status,
          });
        }
        return dispatch({ type: "ERROR_PAYMENT_PAYMENT", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_PAYMENT_PAYMENT",
          message: "Network error..",
        });
      });
  };
};

export const actPostDataPayment = (params) => {
  return (dispatch) => {
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_PAYMENT_PAYMENT" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 201) {
          return dispatch({
            type: "SUCCESS_PAYMENT_PAYMENT",
            message: "Success post data",
          });
        }
        return dispatch({ type: "ERROR_PAYMENT_PAYMENT", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_PAYMENT_PAYMENT",
          message: "Network error..",
        });
      });
  };
};

export const actUpdateDataPayment = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({
      url: post + "/" + atob(id),
      data: params,
      method: "PUT",
    });
    dispatch({ type: "LOADING_PAYMENT_PAYMENT" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_PAYMENT_PAYMENT",
            message: "Success put data",
          });
        }
        return dispatch({ type: "ERROR_PAYMENT_PAYMENT", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_PAYMENT_PAYMENT",
          message: "Network error..",
        });
      });
  };
};

export const actDetailDataPayment = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + atob(id), method: "GET" });
    dispatch({ type: "LOADING_PAYMENT_PAYMENT", id: id });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "DETAIL_PAYMENT_PAYMENT",
            message: "Success get data",
            data: data,
            code: 201,
          });
        }
        return dispatch({ type: "ERROR_PAYMENT_PAYMENT", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_PAYMENT_PAYMENT",
          message: "Network error..",
        });
      });
  };
};

export const actDeleteDataPayment = (params) => {
  return (dispatch) => {
    const { id, siswa } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "DELETE" });
    dispatch({ type: "LOADING_PAYMENT_PAYMENT" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Data payment has been deleted");
          return dispatch(actGetDataPaymentBySiswa(siswa));
        }
        return dispatch({ type: "ERROR_PAYMENT_PAYMENT", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_PAYMENT_PAYMENT",
          message: "Network error..",
        });
      });
  };
};
export const actPostDataFilterPayment = (params) => {
  return (dispatch) => {
    let config = CONFIG_({
      url: post + "/filter",
      data: params,
      method: "POST",
    });
    dispatch({ type: "LOADING_PAYMENT_PAYMENT" });
    CONNECTION(config)
      .then((response) => {
        const { status, data, message } = response;
        if (status === 200) {
          successnotify("Payment has been filtered");
          dispatch(__openModal({ modal: "MODAL_FILTER_PAYMENT", open: false }));
          return dispatch({
            type: "SUCCESS_FILTER_PAYMENT",
            message: "Success filter data",
            data: data,
            code: 200,
          });
        }
        warningnotify(response.message);
        return dispatch({ type: "ERROR_PAYMENT_PAYMENT", message: "failed" });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_PAYMENT_PAYMENT",
          message: "Network error..",
        });
      });
  };
};
