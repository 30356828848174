const defaultState = {
  status: "default",
  data: [],
  code: 500,
  message: "",
  detail: {},
  id: "",
};

let response = {};
const Invoice_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_INVOICE_INVOICE":
      response = {
        ...state,
        data: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        status: "success",
        message: action.message ? action.message : "",
      };

      break;
    case "SUCCESS_INVOICE_BYID":
      response = {
        ...state,
        data: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        status: "siswa",
        message: action.message ? action.message : "",
      };

      break;

    case "SUCCESS_INVOICECREATE_INVOICE":
      response = {
        ...state,
        code: action.code ? action.code : 500,
        status: "created",
        message: action.message ? action.message : "",
      };

      break;

    case "SUCCESS_INVOICEFILTER_INVOICE":
      response = {
        ...state,
        data: action.data ? action.data : "",
        code: action.code ? action.code : 500,
        status: "filtered",
        message: action.message ? action.message : "",
      };
      break;

    case "DETAIL_INVOICE_INVOICE":
      response = {
        ...state,
        detail: action.detail ? action.detail : {},
        id: action.id ? action.id : {},
        code: action.code ? action.code : 500,
        status: "detail",
        message: action.message ? action.message : "",
      };

      break;
    case "LOADING_INVOICE_INVOICE":
      response = {
        ...state,
        status: "loading",
      };
      break;
    case "ERROR_INVOICE_INVOICE":
      response = {
        ...state,
        status: "error",
        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
      };
  }

  return response;
};

export default Invoice_lib;
