const defaultState = {
  status: "default",
  data: [],
  detail: [],
  code: 500,
  message: "",
  id: "",
};

let response = {};
const SiswaNotification_lib = (state = defaultState, action = {}) => {
  switch (action.type) {
    case "SUCCESS_SISWANOTIFICATION_SISWA":
      response = {
        ...state,
        data: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        status: "success",
        message: action.message ? action.message : "",
      };

      break;
    case "SUCCESS_SISWANOTIFICATION_SEND":
      response = {
        ...state,
        data: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        status: "send",
        message: action.message ? action.message : "",
      };

      break;
    case "SUCCESS_SISWANOTIFICATION_DETAIL":
      response = {
        ...state,
        detail: action.data ? action.data : {},
        code: action.code ? action.code : 500,
        status: "siswa",
        id: action.id ? action.id : "",
        message: action.message ? action.message : "",
      };

      break;
    case "LOADING_SISWANOTIFICATION_SISWA":
      response = {
        ...state,
        status: "loading",
        id: action.id ? action.id : "",
      };
      break;
    case "ERROR_SISWANOTIFICATION_SISWA":
      response = {
        ...state,
        status: "error",
        id: action.id ? state.id : "",
        message: action.message ? action.message : "",
        code: action.code ? action.code : 500,
      };
      break;
    default:
      return {
        ...state,
      };
  }

  return response;
};

export default SiswaNotification_lib;
