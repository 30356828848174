import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";

  var get = "/kabupaten";
  var post = "/kabupaten";
    export const actGetDataKabupaten = () => {
      return (dispatch) => {
        let config = CONFIG_({ url: get, method: "GET" });
        dispatch({ type: "LOADING_KABUPATEN" });
        CONNECTION(config)
          .then((response) => {
            const { status, data} = response;
            if (status === 200) {
              return dispatch({ type: "SUCCESS_KABUPATEN", message: "Success get data", data:data, code:status});
            }
            return dispatch({ type: "ERROR_KABUPATEN", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_KABUPATEN", message: "Network error.." });
          });

      };
    };
    
    export const actPostDataKabupaten = (params) => {
      return (dispatch) => {
        let config = CONFIG_({ url: post, data:params, method: "POST" });
        dispatch({ type: "LOADING_KABUPATEN" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 201) {
              return dispatch({ type: "SUCCESS_KABUPATEN", message: "Success post data" });
            }
            return dispatch({ type: "ERROR_KABUPATEN", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_KABUPATEN", message: "Network error.." });
          });
      };
    };
    
    export const actUpdateDataKabupaten = (params) => {
      return (dispatch) => {
        const {id} = params
        let config = CONFIG_({ url: post+'/'+id, data:params, method: "PUT" });
        dispatch({ type: "LOADING_KABUPATEN" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              return dispatch({ type: "SUCCESS_KABUPATEN", message: "Success put data" });
            }
            return dispatch({ type: "ERROR_KABUPATEN", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_KABUPATEN", message: "Network error.." });
          });
      };
    };
    
    export const actDetailDataKabupaten = (params) => {
      return (dispatch) => {
        const {id} = params
        let config = CONFIG_({ url: post+'/'+id, method: "GET" });
        dispatch({ type: "LOADING_KABUPATEN" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              return dispatch({ type: "SUCCESS_KABUPATEN", message: "Success get data" });
            }
            return dispatch({ type: "ERROR_KABUPATEN", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_KABUPATEN", message: "Network error.." });
          });
      };
    };

        export const actDeleteDataKabupaten = (params) => {
      return (dispatch) => {
        const {id} = params
        let config = CONFIG_({ url: post+'/'+id, method: "DELETE" });
        dispatch({ type: "LOADING_KABUPATEN" });
        CONNECTION(config)
          .then((response) => {
            const { status } = response;
            if (status === 200) {
              return dispatch({ type: "SUCCESS_KABUPATEN", message: "Success get data" });
            }
            return dispatch({ type: "ERROR_KABUPATEN", message: "failed" });
          })
          .catch((response) => {
            console.log(response);
            return dispatch({ type: "ERROR_KABUPATEN", message: "Network error.." });
          });
      };
    };