import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";

var get = "/profile";
var post = "/profile";
export const actGetDataProfile = token => {
  return dispatch => {
    let config = CONFIG_({
      url: get,
      method: "GET"
    });
    dispatch({ type: "LOADING_PROFILE_AUTH" });
    CONNECTION(config)
      .then(response => {
        const { status, data } = response;
        if (status === 201) {
          return dispatch({
            type: "SUCCESS_PROFILE_AUTH",
            message: "Success get data",
            data: data,
            code: status
          });
        }
        return dispatch({ type: "ERROR_PROFILE_AUTH", message: "failed" });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_PROFILE_AUTH",
          message: "Network error.."
        });
      });
  };
};

export const actValidateAuth = params => {
  return dispatch => {
    let config = CONFIG_({
      url: post,
      method: "GET",
      headers: {
        Authorization: `Bearer ` + localStorage.getItem("access_token")
      }
    });
    dispatch({ type: "LOADING_PROFILE_AUTH" });
    CONNECTION(config)
      .then(response => {
        const { status, data } = response;
        if (status === 201) {
          return dispatch({
            type: "SUCCESS_PROFILE_AUTH",
            message: "Success post data",
            code: status,
            data: data
          });
        }
        return dispatch({ type: "ERROR_PROFILE_AUTH", message: "failed" });
      })
      .catch(response => {
        console.log(response);
        return dispatch({
          type: "ERROR_PROFILE_AUTH",
          message: "Network error.."
        });
      });
  };
};
