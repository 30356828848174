import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify } from "../../components/ToasNotif/Toast";
import { __openModal } from "../Modal";

var get = "/book";
var post = "/book";
export const actGetDataBook = () => {
  return dispatch => {
    let config = CONFIG_({ url: get, method: "GET" });
    dispatch({ type: "LOADING_BOOK" });
    CONNECTION(config)
      .then(response => {
        const { status, data } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_BOOK",
            message: "Success get data",
            data: data,
            code: status
          });
        }
        return dispatch({ type: "ERROR_BOOK", message: "failed" });
      })
      .catch(response => {
        console.log(response);
        return dispatch({ type: "ERROR_BOOK", message: "Network error.." });
      });
  };
};

export const actPostDataBook = params => {
  return dispatch => {
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_BOOK" });
    CONNECTION(config)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          successnotify("Book has been created");
          dispatch(__openModal({ modal: "MODAL_ADD_BOOK", open: false }));
          dispatch(actGetDataBook());
        }
        return dispatch({ type: "ERROR_BOOK", message: "failed" });
      })
      .catch(response => {
        console.log(response);
        return dispatch({ type: "ERROR_BOOK", message: "Network error.." });
      });
  };
};

export const actUpdateDataBook = params => {
  return dispatch => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, data: params, method: "PUT" });
    dispatch({ type: "LOADING_BOOK" });
    CONNECTION(config)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_BOOK",
            message: "Success put data"
          });
        }
        return dispatch({ type: "ERROR_BOOK", message: "failed" });
      })
      .catch(response => {
        console.log(response);
        return dispatch({ type: "ERROR_BOOK", message: "Network error.." });
      });
  };
};

export const actDetailDataBook = params => {
  return dispatch => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "GET" });
    dispatch({ type: "LOADING_BOOK" });
    CONNECTION(config)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_BOOK",
            message: "Success get data"
          });
        }
        return dispatch({ type: "ERROR_BOOK", message: "failed" });
      })
      .catch(response => {
        console.log(response);
        return dispatch({ type: "ERROR_BOOK", message: "Network error.." });
      });
  };
};

export const actDeleteDataBook = params => {
  return dispatch => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "DELETE" });
    dispatch({ type: "LOADING_BOOK" });
    CONNECTION(config)
      .then(response => {
        const { status } = response;
        if (status === 200) {
          successnotify("Data has been deleted");
          dispatch(actGetDataBook());
        }
        return dispatch({ type: "ERROR_BOOK", message: "failed" });
      })
      .catch(response => {
        console.log(response);
        return dispatch({ type: "ERROR_BOOK", message: "Network error.." });
      });
  };
};
