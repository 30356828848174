import React from "react";

import { Redirect } from "react-router-dom";

// import SettingPage from '../pages/Setting/SettingPage';
// import TypesettingPage from '../pages/Typesetting/TypesettingPage';
// import VasettingPage from '../pages/Vasetting/VasettingPage';

const SettingPage = React.lazy(() => import("../pages/Setting/SettingPage"));
const TypesettingPage = React.lazy(() =>
  import("../pages/Typesetting/TypesettingPage")
);
const VasettingPage = React.lazy(() =>
  import("../pages/Vasetting/VasettingPage")
);

const DashboardPage = React.lazy(() => import("../pages/Dashboard"));
const TypeuserPage = React.lazy(() => import("../pages/Typeuser/TypeuserPage"));
const DetailTypeuser = React.lazy(() =>
  import("../pages/Typeuser/DetailTypeuserPage")
);
const UserPage = React.lazy(() => import("../pages/user/UserPage"));
const TypeagamaPage = React.lazy(() =>
  import("../pages/Typeagama/TypeagamaPage")
);
const TypeabsensiPage = React.lazy(() =>
  import("../pages/Typeabsensi/TypeabsensiPage")
);
const TypetatibPage = React.lazy(() =>
  import("../pages/Typetatib/TypetatibPage")
);
const TypebookPage = React.lazy(() => import("../pages/Typebook/TypebookPage"));
const TypepenerbitPage = React.lazy(() =>
  import("../pages/Typepenerbit/TypepenerbitPage")
);
const TypeitemPage = React.lazy(() => import("../pages/Typeitem/TypeitemPage"));
const TypePendidikanPage = React.lazy(() =>
  import("../pages/typePendidikan/TypePendidikanPage")
);
const TypeinvoicePage = React.lazy(() =>
  import("../pages/Typeinvoice/TypeinvoicePage")
);
const TypestatusPage = React.lazy(() =>
  import("../pages/Typestatus/TypestatusPage")
);
const TypepaymentPage = React.lazy(() =>
  import("../pages/Typepayment/TypepaymentPage")
);
const TypekelasPage = React.lazy(() =>
  import("../pages/Typekelas/TypekelasPage")
);
const TyperegistrationPage = React.lazy(() =>
  import("../pages/Typeregistration/TyperegistrationPage")
);
const TypepelajaranPage = React.lazy(() =>
  import("../pages/Typepelajaran/TypepelajaranPage")
);
const TypefamilyPage = React.lazy(() =>
  import("../pages/Typefamily/TypefamilyPage")
);

/** SISWA PAGE */
const SiswaPage = React.lazy(() => import("../pages/Siswa/SiswaPage"));
const DetailPage = React.lazy(() => import("../pages/Siswa/DetailPage"));
const EditSiswaPage = React.lazy(() => import("../pages/Siswa/EditSiswaPage"));
const SettingPaymentPage = React.lazy(() =>
  import("../pages/Settingpayment/SettingPaymentPage")
);
const SiswaLoginDetail = React.lazy(() =>
  import("../pages/SiswaLogin/SiswaLoginDetail/SiswaLoginDetail")
);

/** GURU PAGE */
const GuruPage = React.lazy(() => import("../pages/Guru/GuruPage"));
const GuruDetail = React.lazy(() => import("../pages/Guru/GuruDetail"));
const GuruEdit = React.lazy(() => import("../pages/Guru/EditGuruPage"));

/** RUMBEL PAGE */
const RumbelList = React.lazy(() => import("../pages/Rumbel/RumbelPage"));
const RumbelDetail = React.lazy(() => import("../pages/Rumbel/RumbelDetail"));

const ProvinsiPage = React.lazy(() => import("../pages/Provinsi/ProvinsiPage"));
const KabupatenPage = React.lazy(() =>
  import("../pages/Kabupaten/KabupatenPage")
);

const InvoicePage = React.lazy(() => import("../pages/Invoice/InvoicePage"));
const AddInvoicePage = React.lazy(() =>
  import("../pages/Invoice/AddInvoicePage")
);
const DetailInvoicePage = React.lazy(() =>
  import("../pages/Invoice/DetailInvoicePage")
);

const PaymentList = React.lazy(() => import("../pages/Invoice/PaymentList"));
const BookPage = React.lazy(() => import("../pages/Book/BookPage"));
const MenuPage = React.lazy(() => import("../pages/menu/MenuPage"));
const PelajaranPage = React.lazy(() =>
  import("../pages/Pelajaran/PelajaranPage")
);
// const SettingPage = React.lazy(() => import('../pages/Setting/SettingPage'));

const MatapelajaranPage = React.lazy(() =>
  import("../pages/Matapelajaran/MatapelajaranPage")
);
const PaymentDetailPage = React.lazy(() =>
  import("../pages/payment/PaymentDetailPage")
);

const PaymentPage = React.lazy(() => import("../pages/payment/PaymentPage"));
const InvoiceDraftPage = React.lazy(() =>
  import("../pages/InvoiceDraft/InvoiceDraftPage")
);
const InvoiceDraftDetailPage = React.lazy(() =>
  import("../pages/InvoiceDraft/InvoiceDraftDetailPage")
);
const CreateInvoiceFlexPage = React.lazy(() =>
  import("../pages/Invoice/CreateInvoiceFexPage")
);

const modulPage = React.lazy(() => import("../pages/modul/ModulPage"));

/** Notification page */
const SiswaNotification = React.lazy(() =>
  import("../pages/Notification/NotificationPage")
);
const CreateNotification = React.lazy(() =>
  import("../pages/Notification/CreateNotificationPage")
);

/** Bulk Page */
const BulkRumbelMember = React.lazy(() =>
  import("../pages/Rumbel/BulkRumbelMember/BulkRumbelMemberPage")
);
const BulkSiswa = React.lazy(() =>
  import("../pages/Siswa/BulkSiswa/BulkSiswaPage")
);

//User Profile
const userProfile = React.lazy(() =>
  import("../pages/Authentication/user-profile")
);

const OwnRoutes = [
  { path: "/", component: DashboardPage },
  { path: "/user", component: UserPage },
  { path: "/menu", component: MenuPage },

  // User Profile
  { path: "/user-profile", component: userProfile },

  /** Options */
  { path: "/typeagama", component: TypeagamaPage },
  { path: "/typeabsensi", component: TypeabsensiPage },
  { path: "/typetatib", component: TypetatibPage },
  { path: "/typebook", component: TypebookPage },
  { path: "/typepenerbit", component: TypepenerbitPage },
  { path: "/typeitem", component: TypeitemPage },
  { path: "/typependidikan", component: TypePendidikanPage },
  { path: "/typeinvoice", component: TypeinvoicePage },
  { path: "/typeuser", component: TypeuserPage },
  { path: "/typeuser/:id", component: DetailTypeuser },

  { path: "/typestatus", component: TypestatusPage },
  { path: "/typepayment", component: TypepaymentPage },
  { path: "/typekelas", component: TypekelasPage },
  { path: "/typeregistration", component: TyperegistrationPage },
  { path: "/typepelajaran", component: TypepelajaranPage },
  { path: "/typefamily", component: TypefamilyPage },
  { path: "/provinsi", component: ProvinsiPage },
  { path: "/kabupaten", component: KabupatenPage },

  /** Siswa Page */
  { path: "/siswa", component: SiswaPage },
  { path: "/siswa/detail/:id", component: DetailPage },
  { path: "/siswa/edit/:id", component: EditSiswaPage },
  { path: "/siswa/settingpayment/:id", component: SettingPaymentPage },
  { path: "/siswalogin/detail/:id", component: SiswaLoginDetail },

  /** Notification Siswa */
  { path: "/siswanotification", component: SiswaNotification },
  { path: "/createnotification", component: CreateNotification },

  /** Guru Page */
  { path: "/guru", component: GuruPage },
  { path: "/guru/detail/:id", component: GuruDetail },
  { path: "/guru/edit/:id", component: GuruEdit },

  /** Rumbel Page */
  { path: "/rumbel", component: RumbelList },
  { path: "/rumbel/detail/:id", component: RumbelDetail },
  { path: "/book", component: BookPage },
  { path: "/pelajaran", component: PelajaranPage },
  { path: "/matapelajaran", component: MatapelajaranPage },

  /** Payment Page */
  { path: "/paymentlist", component: PaymentList },
  { path: "/payment", component: PaymentPage },
  { path: "/payment/:id", component: PaymentDetailPage },
  { path: "/invoicedraft", component: InvoiceDraftPage },
  { path: "/invoicedraft/:id", component: InvoiceDraftDetailPage },
  { path: "/addinvoiceflex", component: CreateInvoiceFlexPage },
  { path: "/addinvoice", component: AddInvoicePage },
  { path: "/detailinvoice/:invoiceId", component: DetailInvoicePage },
  { path: "/invoice", component: InvoicePage },

  /** Bulk Page */
  { path: "/bulkrumbelmember", component: BulkRumbelMember },
  { path: "/bulksiswa", component: BulkSiswa },

  { path: "/setting", component: SettingPage },
  { path: "/typesetting", component: TypesettingPage },
  { path: "/vasetting", component: VasettingPage },

  { path: "/modul", component: modulPage },

  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/" />,
  },
];

export default OwnRoutes;
