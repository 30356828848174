import React, { Suspense, useEffect } from "react";
import { Redirect, Route } from "react-router-dom";
import { setAuthorization } from "../../vendor/helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";

import { useProfile } from "../../vendor/Components/Hooks/UserHooks";

// import { logoutUser } from '../../vendor/store/actions';
import TopBarProgress from "react-topbar-progress-indicator";

const AuthProtected = (props) => {
  // const dispatch = useDispatch();
  const { userProfile, loading, token } = useProfile();
  const modulProfile = useSelector((state) => state.modulProfile);
  const { auth } = modulProfile;
  // useEffect(() => {
  //   if (userProfile && !loading && token) {
  //     setAuthorization(token);
  //   } else if (!userProfile && loading && !token) {
  //     dispatch(logoutUser());
  //   }
  // }, [token, userProfile, loading, dispatch]);

  /*
    redirect is un-auth access protected routes via url
    */

  if (auth === false) {
    return <Redirect to={{ pathname: "/", state: { from: props.location } }} />;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <React.Fragment>
            <SuspensedView>
              <Component {...props} />
            </SuspensedView>
          </React.Fragment>
        );
      }}
    />
  );
};

const SuspensedView = ({ children }) => {
  TopBarProgress.config({
    barColors: {
      0: "#2E3230",
      "1.0": "#2E3230",
    },
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};
export { AuthProtected, AccessRoute };
