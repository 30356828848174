import { CONNECTION } from "../../config/Connections";
import { CONFIG_ } from "../../config/Config";
import { successnotify, warningnotify } from "../../components/ToasNotif/Toast";
import { __openModal } from "../Modal";

var get = "/ruangbelajarmember";
var post = "/ruangbelajarmember";
export const actGetDataRumbelMember = (id = "", filter = "rumbel") => {
  return (dispatch) => {
    let config = CONFIG_({
      url: get + "/" + id + "?filter=" + filter,
      method: "GET",
    });
    dispatch({ type: "LOADING_RUMBELMEMBER_RUMBEL" });
    CONNECTION(config)
      .then((response) => {
        const { status, data } = response;
        if (status === 200 || data.length > 0) {
          return dispatch({
            type: "SUCCESS_RUMBELMEMBER_RUMBEL",
            message: "Success get data",
            data: data,
            code: status,
          });
        }
        return dispatch({
          type: "ERROR_RUMBELMEMBER_RUMBEL",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_RUMBELMEMBER_RUMBEL",
          message: "Network error..",
        });
      });
  };
};

export const actPostDataRumbelMember = (params) => {
  return (dispatch) => {
    const { roomId } = params;
    let config = CONFIG_({ url: post, data: params, method: "POST" });
    dispatch({ type: "LOADING_RUMBELMEMBER_RUMBEL" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Student has been added to class room");
          dispatch(__openModal({ modal: "MODAL_ADD_ROOMEMBER", open: false }));
          return dispatch(actGetDataRumbelMember(roomId));
        }
        warningnotify(response.message);
        return dispatch({
          type: "ERROR_RUMBELMEMBER_RUMBEL",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_RUMBELMEMBER_RUMBEL",
          message: "Network error..",
        });
      });
  };
};

export const actUpdateDataRumbelMember = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, data: params, method: "PUT" });
    dispatch({ type: "LOADING_RUMBELMEMBER_RUMBEL" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_RUMBELMEMBER_RUMBEL",
            message: "Success put data",
          });
        }
        warningnotify(response.message);
        return dispatch({
          type: "ERROR_RUMBELMEMBER_RUMBEL",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_RUMBELMEMBER_RUMBEL",
          message: "Network error..",
        });
      });
  };
};

export const actDetailDataRumbelMember = (params) => {
  return (dispatch) => {
    const { id } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "GET" });
    dispatch({ type: "LOADING_RUMBELMEMBER_RUMBEL" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          return dispatch({
            type: "SUCCESS_RUMBELMEMBER_RUMBEL",
            message: "Success get data",
          });
        }
        return dispatch({
          type: "ERROR_RUMBELMEMBER_RUMBEL",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_RUMBELMEMBER_RUMBEL",
          message: "Network error..",
        });
      });
  };
};

export const actDeleteDataRumbelMember = (params) => {
  return (dispatch) => {
    const { id, roomId } = params;
    let config = CONFIG_({ url: post + "/" + id, method: "DELETE" });
    dispatch({ type: "LOADING_RUMBELMEMBER_RUMBEL" });
    CONNECTION(config)
      .then((response) => {
        const { status } = response;
        if (status === 200) {
          successnotify("Student has been deleted from class room");
          return dispatch(actGetDataRumbelMember(roomId));
        }
        warningnotify(response.message);
        return dispatch({
          type: "ERROR_RUMBELMEMBER_RUMBEL",
          message: "failed",
        });
      })
      .catch((response) => {
        console.log(response);
        return dispatch({
          type: "ERROR_RUMBELMEMBER_RUMBEL",
          message: "Network error..",
        });
      });
  };
};
